<template>
  <v-app>
    <AdminShareheader />
    <router-view></router-view>
    <AdminSharefooter />
  </v-app>
</template>
<script>
import AdminShareheader from "@/components/AdminShareheader";
import AdminSharefooter from "@/components/AdminSharefooter";

export default {
  name: "app",
  components: {
    AdminShareheader,
    AdminSharefooter,
  },
};
</script>
