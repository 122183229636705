<template>
  <v-app>
    <v-main>
      <v-card width="800px" class="mx-auto mt-16">
        <img
          src="@/assets/logo_main02.png"
          style="width: 100%; height: 56.25%"
        />
        <div class="signin">
          <h2>Administrator</h2>
          <v-card-actions class="justify-center">
            <share-button @buttunclick="azureAdLogin" type="admin"
              >Sign in with AzureAD</share-button
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import shareButton from "@/components/ShareButton.vue";
import Methods from "@/api/methods";

export default {
  components: {
    shareButton,
  },
  data: () => ({}),
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("admin")).data;
    if (user.auth === "not_auth_user") {
      console.log("管理者権限無し");
      this.$router.push("/adminNotAuth");
      return;
    }
    if (user) {
      // ログイン後のページに遷移する
      this.$router.push("/admintop");
    }
  },
  methods: {
    async azureAdLogin () {
      // ログイン
      this.$router.push("/adminsignup");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
</style>
