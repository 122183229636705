<template>
  <v-app>
    <v-main>
      <v-card width="800px" class="mx-auto mt-16">
        <img
          src="@/assets/logo_main02.png"
          style="width: 100%; height: 56.25%"
        />
        <v-card-actions class="justify-center">
          <share-button @buttunclick="azureAdLogin" type="primary"
            >Sign in with Microsoft</share-button
          >
        </v-card-actions>
      </v-card>
      <v-dialog v-model="is_dialog" height="200" width="600">
        <v-card>
          <v-card-title class="headline red white--text text-center"
            >権限エラー</v-card-title
          >
          <v-divider class="mx-3"></v-divider>
          <v-card-text>
            <div class="body-1 mb-1 text--primary">
              <br />当機能を使用する権限がありません。
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="is_dialog = false"
              >閉じる</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import shareButton from "@/components/ShareButton.vue";

export default {
  components: {
    shareButton,
  },
  data: () => ({
    is_dialog: true,
  }),
  async beforeCreate () {},
  methods: {
    async azureAdLogin () {
      // ログイン
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
