<template>
  <v-app>
    <v-main>
      <v-container>
        <v-card>
          <v-row>
            <v-card-title class="ml-3">社員情報</v-card-title>

            <v-spacer></v-spacer>

            <v-btn class="mt-4 mr-8 pr-3" color="primary" @click="exportCsv"
              >CSV一括出力</v-btn
            >
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="memberDatas"
                :search="search"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="絞り込み(社員番号,名前,ポイント,コイン,無効フラグ)"
                    class="mx-4"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.point`]="point">
                  <router-link
                    :to="{
                      name: 'pointmanage',
                      params: { member: point.item },
                    }"
                    >{{ point.value }}</router-link
                  >
                </template>
                <template v-slot:[`item.coin`]="coin">
                  <router-link
                    :to="{ name: 'coinmanage', params: { member: coin.item } }"
                    >{{ coin.value }}</router-link
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <v-overlay :value="overlay">
          <VueLoading
            type="spiningDubbles"
            color="white"
            :size="{ width: '150px', height: '150px' }"
          ></VueLoading>
          <span class="font-weight-bold" style="font-size: 2em">
            now exporting...
          </span>
        </v-overlay>

        <v-dialog v-model="exportcsvfailedDialog" max-width="400">
          <v-card>
            <v-card-title class="headline"> 出力失敗・・・ </v-card-title>
            <v-divider class="mx-3"></v-divider>
            <v-card-text>
              <div class="body-1 mb-1 text--primary">
                <br />一括出力中にエラーが<br />発生しました。
                <br />お手数ですが、再度出力を <br />お試しください。
              </div>
            </v-card-text>
            <v-row justify="center">
              <v-col></v-col>
              <v-col>
                <v-img
                  height="150"
                  width="150"
                  src="@/assets/send_failed.png"
                ></v-img>
              </v-col>
              <v-col></v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="exportcsvfailedDialog = false"
                >閉じる</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Methods from "@/api/methods";
import { VueLoading } from "vue-loading-template";

export default {
  components: {
    VueLoading,
  },

  data () {
    return {
      headers: [
        {
          text: "社員番号",
          value: "accountnumber",
        },
        {
          text: "名前",
          value: "membername",
        },
        {
          text: "所持ポイント数",
          value: "point",
        },
        {
          text: "所持コイン数",
          value: "coin",
        },
        {
          text: "無効フラグ",
          value: "invalidFlag",
        },
      ],
      memberDatas: [],
      search: "",
      //エクスポート失敗ダイアログ
      exportcsvfailedDialog: false,
      //処理中ローディング画面の初期表示フラグ
      overlay: false,
    };
  },
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("admin")).data;
    if (!user) {
      console.log("ログイン画面へ遷移");
      this.$router.push("/adminsignin");
      return;
    }
    if (user.auth === "not_auth_user") {
      console.log("ユーザ権限無し");
      this.$router.push("/adminNotAuth");
      return;
    }
    this.user = user;
    console.log("ユーザ情報は：", JSON.stringify(user));
  },
  created () {
    // キャッシュ削除
    if (process.env.NODE_ENV === "production") {
      window.navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      caches.keys().then((keys) => {
        keys.forEach((cacheName) => {
          if (cacheName) {
            caches.delete(cacheName);
          }
        });
      });
    }
  },
  watch: {
    $route () {
      this.getDisplayDatas();
    },
  },
  methods: {
    async getDisplayDatas () {
      //membergetに引数を渡さなければ全件検索してくれる
      let resMemberdata = await Methods.memberget("admin");
      this.memberDatas = resMemberdata.data;
    },
    filterOnlyCapsText (value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },

    //csvエクスポート処理
    async exportCsv () {
      try {
        this.overlay = true; //処理中ローディング画面をオン
        //メソッド呼び出し
        let res = await Methods.exportUserCsv();
        if (res.data.code === -1) {
          //エラーコードが返って来た時
          this.overlay = false; //処理中ローディング画面をオフ
          this.exportcsvfailedDialog = true; //エクスポート失敗ダイアログをオン
          return;
        } else {
          //成功時、ダウンロードをする
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const blob = new Blob([bom, res.data], { type: "text/csv" });
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.setAttribute("download", "UserInfo.csv");
          a.click();
          a.remove();
          this.overlay = false; //処理中ローディング画面をオフ
          console.log("エクスポート成功");
        }
      } catch (err) {
        //予期せぬエラーが起きた時
        this.overlay = false; //処理中ローディング画面をオフ
        this.exportcsvfailedDialog = true; //エクスポート失敗ダイアログをオン
      }
    },
  },
  mounted () {
    this.getDisplayDatas();
  },
};
</script>
