import Api from "./index";

export default {
  getApi (mode) {
    return Api(mode);
  },
  // API処理ここに追加していく
  testPosting (mode) {
    const item = {
      text: "POST Success!",
    };
    return this.getApi(mode).post("/test", item);
  },
  //http://パブリックIPアドレス:8080/APItestで確認用
  testDynamicPosting (mode, item) {
    return this.getApi(mode).post("/test", item);
  },
  //以下本番用
  memberget (mode, query) {
    return this.getApi(mode).get("/auth/getmember", {
      params: {
        accountnumber: query,
      },
    });
  },
  getAuthUserInfo (mode) {
    return this.getApi(mode).get("/auth/getAuthUserInfo");
  },
  membersearch (mode, query) {
    return this.getApi(mode).get("/auth/membersearch", {
      params: {
        searchindex: query,
      },
    });
  },
  //マイページでMongoDBから履歴取得用のAPI
  getUsePointHistory (mode, query) {
    return this.getApi(mode).get("/getusepointhistory", {
      params: {
        key: query,
      },
    });
  },
  userImport (mode, params) {
    return this.getApi(mode).put("/userImport", params);
  },
  sendmail (mode, params) {
    return this.getApi(mode).post("/sendmail", params);
  },
  senderrmail (mode, params) {
    return this.getApi(mode).post("/senderrmail", params);
  },
  sendqrcodemail (mode, params) {
    return this.getApi(mode).post("/sendqrcodemail", params);
  },
  thanksgiftsend (mode, value) {
    return this.getApi(mode).put("/thanksgiftsend", value);
  },
  thankscardsend (mode, value) {
    return this.getApi(mode).put("/thankscardsend", value);
  },
  givepoint (mode, value) {
    return this.getApi(mode).put("/givepoint", value);
  },
  givecoin (mode, value) {
    return this.getApi(mode).put("/givecoin", value);
  },
  givecoinfromcsv (mode, value) {
    return this.getApi(mode).put("/givecoinfromcsv", value);
  },
  exportUserCsv (mode) {
    return this.getApi(mode).get("/exportUserCsv");
  },
  getAdminPointCoinHistory (mode) {
    return this.getApi(mode).get("/getadminpointcoinhistory");
  },
  exportAdminHistoryCsv (mode) {
    return this.getApi(mode).get("/exportadminpointcoinhistory");
  },
  sendLikedMail (mode, params) {
    return this.getApi(mode).post("/sendlikedmail", params);
  },
  exportSendPointHistoryCsv (mode, query) {
    return this.getApi(mode).get("/exportsendpointhistorycsv", {
      params: {
        from: query.from,
        to: query.to,
      },
    });
  },
  exportUserWholeHistoryCsv (mode, query) {
    return this.getApi(mode).get("/exportuserwholehistorycsv", {
      params: {
        accountnumber: query.accountnumber,
        historyType: query.historyType
      },
    });
  },
};
