<template>
  <v-app id="qrcode">
    <v-main>
      <v-container fluid>
        <v-card>
          <v-tabs v-model="tab" background-color="primary" dark grow>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab v-for="item in tabitems" :key="item.tab" @click="changeTabs(item.tab)">
              {{ item.tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in tabitems" :key="item.tab">
              <v-card>
                <div v-if="item.tab === 'よみとる'">
                  <v-container pt-0>
                    <v-row
                      justify="center"
                      align-content="center"
                      no-gutters
                      class="myx-3 pt-3"
                    >
                      <qr-camera
                        ref="qrCamera"
                        @qrDetected="giftFromQr"
                      />
                    </v-row>
                    <!--ここからはよみとる画面用ダイアログ-->
                    <v-dialog
                      v-model="giftSendConfirmationDialog"
                      persistent
                      max-width="400"
                    >
                      <v-card height="auto" max-width="auto" shaped>
                        <v-row justify="center">
                          <v-col></v-col>
                          <v-col>
                            <v-img
                              height="150"
                              width="150"
                              v-bind:src="require(`@/assets/qrcode.png`)"
                            ></v-img>
                          </v-col>
                          <v-col></v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-card-title class="headline"
                            >ギフトを贈りますか？</v-card-title
                          >
                        </v-row>
                        <v-card-text>
                          <li
                            style="list-style: none"
                            v-for="giftDest in earnCoinMemberMultiple"
                            :key="giftDest.earnUserNum"
                          >
                            {{ giftDest.membername }}さん
                          </li>
                          にギフトを贈ります
                        </v-card-text>

                        <v-textarea
                          class="mx-7"
                          v-model="thanks_message_gift"
                          label="コメント（任意）"
                          rows="1"
                          v-bind:rules="giftcommentrule"
                          maxlength="60"
                          outlined
                          auto-grow
                        ></v-textarea>
                        <v-card-actions>
                          <v-row>
                            <v-col>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="thanksGiftSend()"
                                >はい</v-btn
                              >
                            </v-col>
                            <v-col>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="closeGiftSendDialog()"
                                >いいえ</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-overlay :value="overlay">
                      <VueLoading
                        type="spiningDubbles"
                        color="white"
                        :size="{ width: '150px', height: '150px' }"
                      ></VueLoading>
                      <span class="font-weight-bold" style="font-size: 2em">
                        now sending...
                      </span>
                    </v-overlay>

                    <v-dialog v-model="sendGiftSuccessDialog" max-width="400" persistent>
                      <v-card height="auto" max-width="auto" shaped>
                        <v-row justify="center">
                          <v-col></v-col>
                          <v-col>
                            <v-img
                              height="150"
                              width="150"
                              src="@/assets/getcoin.png"
                            ></v-img
                          ></v-col>
                          <v-col></v-col>
                          <v-card-title>
                            <div class="body-1 mb-1 text--primary">
                              <br />{{ sendpoint }}coin 贈呈しました！
                            </div>
                          </v-card-title>
                          <v-card-text v-if="!isenoughPoint" class="red--text">
                            所持ポイントが不足していたため、<br />送信先にコインは追加されません。
                          </v-card-text>
                          <v-card-text v-if="!isdifferentCompany">
                            グループ他社の仲間へは<br />コインは追加されません。
                          </v-card-text>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeSendGiftSuccessDialog()"
                            >閉じる</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-container>
                </div>
                <div v-if="item.tab === 'つくる'">
                  <v-container>
                    <div class="container">
                      <v-textarea
                        v-model="inputText"
                        label="タイトル（例...XXX企画など）"
                        rows="1"
                        v-bind:rules="qrContentRule"
                        maxlength="60"
                        prepend-icon="mdi-pen"
                        outlined hide-details="false"
                        auto-grow
                        :disabled="isDisabled"
                      ></v-textarea>
                      <p style="text-align: left; margin-left: 40px; font-size: 14px;">
                        よみとり易いQRコードをつくるには<br/>
                        10文字以内の設定がおススメです
                      </p>
                    </div>
                    <br />
                    <v-row justify="center">
                      <v-col class="mb-5 mt-0 pt-0">
                        <v-btn
                          color="primary"
                          elevation="2"
                          :disabled="!(!showQr&&inputText)"
                          @click="qrcodeGenerator()"
                          >QRコード生成
                        </v-btn>
                        <div v-show="showQr">
                          <br />
                          <div>
                            <canvas id="qrBackgroundId" width="240" height="300">
                            </canvas>
                          </div>
                          <div style="display:none;">
                            <vue-qrcode
                              id="qrcodeId"
                              :value="genQr"
                              :options="{
                              errorCorrectionLevel: 'H',
                              height: '200',
                              width: '200',
                              }"
                            >
                            </vue-qrcode>
                          </div>
                          <p>
                            <br />
                            <v-btn
                              color="primary"
                              elevation="2"
                              @click="deleteQrcode()"
                            >やりなおす
                            </v-btn>
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-card>
            </v-tab-item>
            <v-dialog v-model="failureDialog" max-width="400" persistent>
              <GenericFailureCard
                :title="failureMessage.title"
                :text="failureMessage.text"
                @dialogClosed="closeFailureDialog()"
              >
              </GenericFailureCard>
            </v-dialog>
          </v-tabs-items>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import Methods from "@/api/methods";
import QrCamera from "../components/QrCamera";
import GenericFailureCard from "../components/GenericFailureCard";

export default {
  components: {
    VueQrcode,
    QrCamera,
    GenericFailureCard,
  },
  data () {
    return {
      tab: null,
      tabitems: [
        { tab: "よみとる", content: "" },
        { tab: "つくる", content: "" },
      ],
      //処理中ローディング画面の初期表示フラグ
      overlay: false,
      //サンクスギフト送信確認ダイアログの初期表示フラグ
      giftSendConfirmationDialog: false,
      //サンクスギフト送信成功ダイアログの初期表示フラグ
      sendGiftSuccessDialog: false,
      //サンクスギフト送信時のコメント文字数制限
      giftcommentrule: [(text) => text.length <= 60 || "最大文字数は60文字です"],
      //サンクスギフト送信先配列
      earnCoinMemberMultiple: [],
      //汎用失敗ダイアログ
      failureDialog: false,
      failureMessage: {
        title: "",
        text: "",
      },
      //ポイント渡し処理の成否判定
      isenoughPoint: true,
      isdifferentCompany: true,
      thanks_message_gift: "",
      sendpoint: 10,
      //QRコード生成用の処理
      inputText: "",
      loginUser: null,
      genQr: "",
      //QR生成後の表示エリア
      showQr: false,
      //テキストボックスの入力制御
      isDisabled: false,
      //QRコード作成時のコメント文字数制限
      qrContentRule: [(text) => text.length <= 60 || "最大文字数は60文字です"],
    };
  },
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("user")).data;
    if (!user) {
      // 未ログインもしくは管理者用ユーザでログイン状態。ログイン画面へ遷移する
      this.$router.push("/");
      return;
    }
    if (user.auth === "not_auth_user") {
      console.log("ユーザ権限無し");
      this.$router.push("/notAuth");
      return;
    }
    this.loginUser = user;
  },

  methods: {
    //以下はQRコード読み取りの処理
    //QRコード読み取り用カメラ
    async giftFromQr (qrString) {
      this.failureDialog = false;
      if (!this.loginUser) {
        this.loginUser = (await Methods.getAuthUserInfo("user")).data;
      }
      let qrJson;
      try {
        qrJson = JSON.parse(qrString);
      } catch (error) {
        this.failureMessage.title = "";
        this.failureMessage.text = "他アプリで作成したQRコードは<br />よみとることができません。";
        this.failureDialog = true;
        return;
      }
      if (!(qrJson.ac && qrJson.in)) {
        this.failureMessage.title = "";
        this.failureMessage.text = "他アプリで作成したQRコードは<br />よみとることができません。";
        this.failureDialog = true;
        return;
      }
      //自分(ログイン中のユーザー)が選択されていないかチェック
      if (qrJson.ac === this.loginUser.accountnumber) {
        this.failureMessage.title = "";
        this.failureMessage.text = "送信先が自分自身のため、<br />送信できません。";
        this.failureDialog = true;
        return;
      }
      try {
        const response = await Methods.memberget("user", qrJson.ac);
        if (response.data.error) {
          throw new Error(response.data.error);
        }
        if (response.data.length === 0) {
          this.failureMessage.title = "";
          this.failureMessage.text = "宛先が見つかりません。";
          this.failureDialog = true;
          return;
        }
        //宛先設定
        this.earnCoinMemberMultiple = [{
          useUserNum: this.loginUser.accountnumber,
          earnUserNum: qrJson.ac,
          mail: response.data[0].mail,
          membername: response.data[0].membername,
          department: response.data[0].department,
          picturetype: "qrcode",
        }];
        this.thanks_message_gift = qrJson.in;

        if (this.loginUser.point < 10) {
          this.sendpoint = 0;
        }
        //後続処理は確認ダイアログから
        this.giftSendConfirmationDialog = true;
      } catch (err) {
        console.error(err);
        this.failureMessage.title = "";
        this.failureMessage.text = "宛先が見つかりません。";
        this.failureDialog = true;
      }
    },
    async thanksGiftSend () {
      try {
        this.giftSendConfirmationDialog = false; //画面のダイアログ表示フラグをOFF
        this.overlay = true; //オーバレイをON

        //ユーザログイン確認処理
        const user = (await Methods.getAuthUserInfo("user")).data;
        if (!user) {
          console.log("ログイン画面へ遷移");
          this.$router.push("/");
          return;
        }
        if (user.auth === "not_auth_user") {
          console.log("ユーザ権限無し");
          this.$router.push("/notAuth");
          return;
        }

        let isusepoint = false; //ポイント送信処理成功フラグ
        let isearncoin = false; //ポイント送信履歴更新処理フラグ
        this.isenoughPoint = true; //ポイント足りているかフラグ
        this.isdifferentCompany = true; //会社同じかフラグ

        // ユーザ操作で上書きされたメッセージを代入
        this.earnCoinMemberMultiple[0].message = this.thanks_message_gift;

        const useResponse = await Methods.thanksgiftsend("user", this.earnCoinMemberMultiple);

        //バックエンドからの戻り値を見て後続処理を実行
        if (useResponse.data.code === 1) {
          //入力エラー
          this.overlay = false;
          this.failureMessage.title = "";
          this.failureMessage.text = "送信先が自分自身のため、<br />送信できません。";
          this.failureDialog = true;
          return;
        } else if (
          useResponse.data.code === 0 &&
          useResponse.data.point === "lack"
        ) {
          //ポイント不足の場合、
          //処理完了時のダイアログに注意書きを表示するようフラグ立て
          this.isenoughPoint = false;
          isusepoint = true;
          isearncoin = true;
        } else if (
          useResponse.data.code === 0 &&
          useResponse.data.status === "companydifferent"
        ) {
          //会社が異なる場合、
          //処理完了時のダイアログに注意書きを表示するようフラグ立て
          this.isdifferentCompany = false;
          isusepoint = true;
          isearncoin = true;
        } else if (useResponse.data.code === -1) {
          this.overlay = false;
          this.failureMessage.title = "送信失敗・・・";
          this.failureMessage.text = "送信中にエラーが発生しました。<br />お手数ですが、再度送信を <br />お試しください。";
          this.failureDialog = true;
          return;
        } else {
          console.log("サンクスギフト送信処理正常終了");
          isusepoint = true;
          isearncoin = true;
        }

        // 受信者にメール送信を行う
        if (isusepoint && isearncoin) {
          for (const earnUser of this.earnCoinMemberMultiple) {
            // 送信エラーとなった場合でもエラーメッセージ等は出さないため、
            // 待つ必要がないのでawaitせずに処理を継続する
            Methods.sendmail("user", {
              earnUser,
              sendUser: user,
              type: "gift",
            });
          }
        }

        this.sendpoint = 10;
        this.overlay = false;
        this.sendGiftSuccessDialog = true;
      } catch (err) {
        this.giftSendConfirmationDialog = false; //画面のダイアログ表示フラグをOFF
        this.overlay = false; //オーバレイをOFF
        this.failureMessage.title = "送信失敗・・・";
        this.failureMessage.text = "送信中にエラーが発生しました。<br />お手数ですが、再度送信を <br />お試しください。";
        this.failureDialog = true;

        // 送信エラーとなった場合でもエラーメッセージ等は出さない
        await Methods.senderrmail("user", {
          error: err,
        });
      } finally {
        //入力内容初期化
        this.earnCoinMemberMultiple = []; //選択した送信先情報のクリア
        this.thanks_message_gift = ""; //コメントの初期化
      }
    },
    closeGiftSendDialog () {
      this.thanks_message_gift = "";
      this.giftSendConfirmationDialog = false;
      this.$refs.qrCamera[0].startReadImage();
    },
    closeSendGiftSuccessDialog () {
      this.thanks_message_gift = "";
      this.sendGiftSuccessDialog = false;
      this.$refs.qrCamera[0].startReadImage();
    },
    closeFailureDialog () {
      this.thanks_message_gift = "";
      this.failureDialog = false;
      this.$refs.qrCamera[0].startReadImage();
    },
    changeTabs (changeFrom) {
      if (changeFrom === "つくる") {
        this.$refs.qrCamera[0].cameraStop();
      } else if (changeFrom === "よみとる") {
        this.$refs.qrCamera[0].startReadImage();
      }
    },
    //以下はQRコード生成の処理
    //ボタン押下後QRコード出力
    async qrcodeGenerator () {
      this.failureDialog = false;
      if (!this.inputText) {
        this.failureMessage.title = "QRコード生成失敗・・・";
        this.failureMessage.text = "QR生成中にエラーが<br />発生しました。<br />お手数ですが、再度 <br />お試しください。";
        this.failureDialog = true;
      } else {
        this.showQr = true; //QRコード表示エリアをオン
        const json = {
          ac: this.loginUser.accountnumber,
          in: this.inputText,
        };
        this.genQr = JSON.stringify(json, null, 2);
        this.onReady(document.getElementById("qrcodeId"), json);
        this.isDisabled = true;
      }
    },
    formatDate (date, format) {
      format = format.replace(/yyyy/g, date.getFullYear());
      format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
      format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
      return format;
    },
    onReady (canvas, json) {
      const context = canvas.getContext("2d");
      const image = new Image();
      image.crossorigin = "anonymous";
      image.onload = async () => {
        const width = Math.round(canvas.width * 0.3);
        const x = (canvas.width - width) / 2;
        this.drawImage(context, image, x, x, width, width);
        const qrBackgroundElement = document.getElementById("qrBackgroundId");
        this.drawCanvasOnCanvas(canvas.toDataURL("image/png"), qrBackgroundElement).then(async () => {
          let result = {};
          try {
            result = await Methods.sendqrcodemail("user", {
              sendUser: {
                mail: this.loginUser.mail,
                department: this.loginUser.department,
                membername: this.loginUser.membername,
              },
              inputText: json.in,
              dataUrl: qrBackgroundElement.toDataURL("image/png"),
              date: this.formatDate(new Date(), "yyyy/MM/dd HH:mm"),
            });
            if (result.data.code !== 0) {
              //バックエンドシステムエラーによるメール送信失敗のダイアログ
              this.failureMessage.title = "メール送信失敗";
              this.failureMessage.text = "QRコードの<br />メール送信に失敗しました。<br />手動で保存するか、<br />再作成してください。";
              this.failureDialog = true;
            }
          } catch (err) {
            //バックエンドシステムエラーによるメール送信失敗のダイアログ
            this.failureMessage.title = "メール送信失敗";
            this.failureMessage.text = "QRコードの<br />メール送信に失敗しました。<br />手動で保存するか、<br />再作成してください。";
            this.failureDialog = true;
          }
        });
      };
      image.src = "./img/icons/Icon-512.png";
    },
    drawImage (context, image, x, y, width, height, radius = 4) {
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 2;
      context.shadowBlur = 4;
      context.shadowColor = "#00000040";
      context.lineWidth = 8;
      context.beginPath();
      context.moveTo(x + radius, y);
      context.arcTo(x + width, y, x + width, y + height, radius);
      context.arcTo(x + width, y + height, x, y + height, radius);
      context.arcTo(x, y + height, x, y, radius);
      context.arcTo(x, y, x + width, y, radius);
      context.closePath();
      context.strokeStyle = "#fff";
      context.stroke();
      context.clip();
      context.fillStyle = "#fff";
      context.fillRect(x, x, width, height);
      context.drawImage(image, x, x, width, height);
    },
    //クリアボタン
    deleteQrcode () {
      this.failureDialog = false;
      this.showQr = false; //QRコード表示エリアをオフ
      this.genQr = "";
      //コメントの初期化
      this.inputText = "";
      this.isDisabled = false;
    },
    drawCanvasOnCanvas (dataUrl, canvas) {
      return new Promise((resolve) => {
        const ctx = canvas.getContext("2d");
        // 背景
        ctx.fillStyle = "white";
        ctx.rect(0, 0, 240, 300);
        ctx.fill();
        // 文字
        ctx.beginPath();
        ctx.font = "16px sans-serif";
        ctx.fillStyle = "#2c3e50";
        const text1 = "そらギフトアプリのカメラ";
        const text2 = "から読み取ってください";
        ctx.fillText(text1, ((240 - ctx.measureText(text1).width)) / 2, 240);
        ctx.fillText(text2, ((240 - ctx.measureText(text2).width)) / 2, 270);
        ctx.fill();
        const image = new Image();
        image.onload = () => {
          ctx.drawImage(image, 20, 20);
          resolve();
        };
        image.src = dataUrl;
      });
    },
  },
};
</script>
