<template>
  <div>
    <v-navigation-drawer app v-model="drawer" clipped>
      <v-layout justify-center pt-5 px-4>
        <p class="font-weight-bold mb-2">
          {{ loginUserDepartment }} / {{loginUserName}}
        </p>
      </v-layout>
      <v-list dense nav>
        <v-list-item
          v-for="(nav_list, i) in nav_lists"
          :key="i"
          @click="getCheckURL(nav_list.url)"
        >
          <v-list-item-icon>
            <v-icon>{{ nav_list.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-on:click="movepage">
              {{ nav_list.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="light-green" dark clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="domain === 'www.soragift.com'">
        <v-img
          width="200px"
          height="auto"
          v-bind:src="require('@/assets/logo_main02.png')"
        ></v-img>
      </v-toolbar-title>
      <v-toolbar-title v-else>
        <v-img
          width="200px"
          height="auto"
          v-bind:src="require('@/assets/logo_main02_dev.png')"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import Methods from "@/api/methods";

export default {
  props: {
    //userID: Number,
    //userNAME: String,
  },
  data: () => ({
    drawer: null,
    nav_list: 1,
    nav_lists: [
      { name: "管理者トップ", icon: "mdi-home", url: "/admintop" },
      { name: "ポイント・コイン付与履歴", icon: "mdi-folder-open", url: "/adminPointCoinHistory" },
      {
        name: "利用実績照会",
        icon: "mdi-chart-line",
        url: "/usageInquiry",
      },
      {
        name: "コイン利用",
        icon: "mdi-arrow-left-bold-circle",
        url: "/coinmanage",
      },
      {
        name: "ポイント利用",
        icon: "mdi-arrow-right-bold-circle",
        url: "/pointmanage",
      },
      {
        name: "ユーザ取り込み",
        icon: "mdi-account-multiple",
        url: "/usermanage",
      },
      { name: "ログアウト", icon: "mdi-logout", url: "/adminsignout" },
    ],
    loginuserIcon: "",
    loginUserDepartment: "",
    loginUserName: "",

    //ドメイン格納
    domain: document.domain,
  }),
  async created () {
    console.log("ドメイン：" + document.domain);
    //画面描画前にユーザ情報を確認する
    const user = (await Methods.getAuthUserInfo("admin")).data;
    if (user) {
      // ログイン済み
      this.user = user;
      this.loginUserDepartment = this.user.department;
      this.loginUserName = this.user.membername;
    }
  },
  methods: {
    movepage () {},
    getCheckURL (url) {
      this.$router.push({ path: url, query: { t: Date.now() } });
    },
  },
};
</script>
