<template>
  <v-combobox
    v-model="_model"
    :label="label"
    :items="searchResults"
    no-filter
    :search-input.sync="_searchInput"
    :allow-overflow="false"
    hide-no-data
    return-object
    :multiple="!single"
    :disabled="readonly"
    outlined
    prepend-icon="mdi-account-search"
    @focus="focus"
    @blur="blur"
    @change="change"
    @keydown="keydown"
  >
    <!-- 選択した要素はチップで表示 -->
    <template v-slot:selection="data">
      <template>
        <v-chip
          v-if="data.item === Object(data.item)"
          :input-value="data.selected"
          close
          close-icon="mdi-close-circle"
          class="chip--select-multi"
          color="blue"
          text-color="white"
          @click:close="remove(model)"
        >
          {{ model[itemText] }}
          <v-icon light>mdi-account</v-icon>
        </v-chip>
      </template>
    </template>

    <!-- 検索結果の表示部 -->
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-tile-content>{{ data.item }}</v-list-tile-content>
      </template>
      <template v-else>
        <v-list>
          <v-list-item-content>
            <v-list-item-title
            >{{ data.item[firstItemText] + separateText +data.item[secondItemText] }}</v-list-item-title>
            <v-list-item-subtitle
            >{{ data.item[itemSubText] }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list>
      </template>
    </template>
  </v-combobox>
</template>

<script>
import { debounce, isEmpty } from "lodash";

export default {
  props: {
    model: {
      type: [Object, Array, String],
      default: null,
    },
    searchInput: {
      type: String,
      default: "",
    },
    searchFunc: {
      type: Function,
      required: true,
    },
    searchResults: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "",
    },
    firstItemText: {
      type: String,
      default: "",
    },
    secondItemText: {
      type: String,
      default: "",
    },
    separateText: {
      type: String,
      default: "",
    },
    itemSubText: {
      type: String,
      default: "",
    },
    itemIdentifier: {
      type: String,
      default: "",
    },
    single: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    const searchWithInterval = value => {
      return this.searchFunc(value)
        .then(() => {
          return Promise.resolve();
        });
    };
    return {
      searchInputTemp: null,
      focused: false,
      search: debounce(value => {
        //APIの実行を1.0秒に1回に制限
        if (!isEmpty(value)) {
          let searchPromise = searchWithInterval(value);
          Promise.all([searchPromise]).then(() => {
            if (!this.focused) {
              this.focusOut();
            }
          });
        } else {
          this.$emit("update:search-results", []);
        }
      }, 1000),
    };
  },
  computed: {
    _model: {
      get () {
        return this.model;
      },
      set (newVal) {
        return this.$emit("update:model", newVal);
      },
    },
    _searchInput: {
      get () {
        return this.searchInput;
      },
      set (newVal) {
        if (newVal != null) {
          this.searchInputTemp = newVal;
        }
        this.$emit("update:search-input", newVal);
      },
    },
  },
  watch: {
    //インクリメンタルサーチ
    searchInputTemp (val) {
      this.search(val);
    },
    // APIで取得した値のみ入力可能
    model (val, prev) {
      //単一
      if (this.single) {
        if (val !== null && val === Object(val)) {
          this.$emit("update:search-input", val.searchindex);
          this.searchInputTemp = val.searchindex;
        }
        if (!val && !this.searchInput) {
          this.$emit("update:search-results", []);
        }
        if (typeof val === "string") {
          if (prev === Object(prev)) {
            this.$nextTick(() => {
              this.$emit("update:model", prev);
            });
          }
        }
      }
    },
  },
  methods: {
    //チップを削除するメソッド。チップの×ボタンを押下されると発火する。
    remove () {
      if (this.single) {
        this.$emit("update:model", null);
        this.searchInputTemp = null;
      }
    },
    focusOut () {
      if (this.searchResults.length === 1) {
        if ((this.model === null || typeof this.model === "string") && this.searchResults[0].searchindex.indexOf(this.searchInputTemp) !== -1) {
          this.$emit("update:model", this.searchResults[0]);
        }
      } else {
        if (this.model === this.searchInputTemp) {
          this.$emit("update:search-input", this.searchInputTemp);
        }
      }
    },
    focus () {
      this.focused = true;
    },
    blur () {
      this.focused = false;
      if (typeof this.model === "string" && !this.searchInputTemp) {
        this.$emit("update:search-input", this.model);
        this.searchInputTemp = this.model;
      }
      this.$nextTick(() => {
        this.focusOut();
      });
    },
    change (value) {
      if (!value) {
        this.$emit("update:search-results", []);
      }
    },
    keydown () {
      this.focused = true;
    }
  },
};
</script>
