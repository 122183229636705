<template>
  <v-app id="pointManage">
    <v-main>
      <v-container fluid>
        <v-tabs background-color="light-green" dark grow>
          <v-tab>ポイント割当</v-tab>
        </v-tabs>
        <v-card>
          <div id="pointassignment">
            <v-container>
              <v-card-title>ポイント割り当て先</v-card-title>
              <AppCombobox
                label="ポイント割り当て対象を選択(割当対象の情報を入力すると自動検索します)"
                :model.sync="PointAssignmentMember"
                :search-input.sync="InputKeyword"
                :search-func="SearchMember"
                :search-results.sync="SearchResult"
                item-text="membername"
                first-item-text="department"
                second-item-text="membername"
                separate-text=" / "
                item-sub-text="mail"
                item-identifier="accountnumber"
                single
                ref="searchComboBox"
              ></AppCombobox>
            </v-container>
          </div>
          <v-divider class="mx-3"></v-divider>
          <div id="points" class="my-n3">
            <v-form v-model="valid" ref="form">
              <v-container>
                <v-card-title>ポイント数</v-card-title>
                <!-- item-textは画面にレンダリングされる値、item-valueはDOM取得される値 -->
                <v-text-field
                  v-model.number="Managepoint"
                  label="ポイント数を入力してください。減算する場合は頭に - を入力ください"
                  single-line
                  prepend-icon="mdi-currency-usd-circle"
                  :rules="pointRule"
                  required
                  outlined
                ></v-text-field>
              </v-container>
            </v-form>
          </div>
          <v-divider></v-divider>

          <div id="comment" class="my-n3">
            <v-container>
              <v-card-title>メッセージ</v-card-title>
              <v-text-field
                v-model="thanks_message"
                label="コメントを入力して下さい"
                single-line
                prepend-icon="mdi-pen"
                outlined
              ></v-text-field>
            </v-container>
          </div>
          <div id="PointAssignmentButton">
            <v-dialog
              v-model="pointassignmentConfirmationDialog"
              persistent
              max-width="400"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="light-green"
                  class="white--text"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!valid"
                  @click="PointAssignmentDialog"
                >
                  確定
                  <!-- <v-icon right>mdi-gift-outline</v-icon> -->
                </v-btn>
                <v-col></v-col>
              </template>
              <v-card height="auto" max-width="auto" v-if="isdest_check" shaped>
                <v-row justify="center">
                  <v-col></v-col>
                  <v-col>
                    <v-img
                      height="150"
                      width="150"
                      v-bind:src="require('@/assets/putpoint.png')"
                    ></v-img>
                  </v-col>
                  <v-col></v-col>
                </v-row>
                <v-row justify="center">
                  <v-card-title class="headline"
                    >ポイントを割り当てますか？</v-card-title
                  >
                </v-row>
                <v-card-text>
                  {{ PointAssignmentMembername }}さんにポイントを割り当てます
                </v-card-text>
                <v-card-actions>
                  <v-row>
                    <v-col>
                      <v-btn color="blue darken-1" text @click="put_point()"
                        >はい</v-btn
                      >
                    </v-col>
                    <v-col>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="pointassignmentConfirmationDialog = false"
                        >いいえ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>

              <v-card v-else height="auto" max-width="auto" shaped>
                <v-row justify="center">
                  <v-col></v-col>
                  <v-col>
                    <v-img
                      height="150"
                      width="150"
                      src="@/assets/send_failed.png"
                    ></v-img
                  ></v-col>
                  <v-col></v-col>
                </v-row>
                <v-card-text>
                  <div class="body-1 mb-1 text--primary">
                    <br />送信先が未選択のため、
                    <br />送信先を選び直して下さい。
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="pointassignmentConfirmationDialog = false"
                    >閉じる</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="sendfailedDialog" max-width="400">
              <v-card>
                <v-card-title class="headline"> 送信失敗・・・ </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                  <div class="body-1 mb-1 text--primary">
                    <br />送信中にエラーが発生しました。 <br />再送信せずに、
                    <br />エラー内容を確認してください。
                  </div>
                </v-card-text>
                <v-row justify="center">
                  <v-col></v-col>
                  <v-col>
                    <v-img
                      height="150"
                      width="150"
                      src="@/assets/send_failed.png"
                    ></v-img>
                  </v-col>
                  <v-col></v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="sendfailedDialog = false"
                    >閉じる</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <v-overlay :value="loading_overlay">
            <VueLoading
              type="spiningDubbles"
              color="white"
              :size="{ width: '150px', height: '150px' }"
            ></VueLoading>
            <span class="font-weight-bold" style="font-size: 2em">
              now sending...
            </span>
          </v-overlay>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import AppCombobox from "@/components/AppCombobox";
import { VueLoading } from "vue-loading-template";
import Methods from "@/api/methods";
import swal from "sweetalert";

export default {
  components: {
    AppCombobox,
    VueLoading,
  },
  data () {
    return {
      //
      PointAssignmentMember: this.$router.currentRoute.params.member,
      //ポイント割り当てをする人の検索結果(名前)
      SearchResult: this.$router.currentRoute.params.member
        ? [this.$router.currentRoute.params.member]
        : [],
      //インクリメンタルサーチのキーワード(前方一致)
      InputKeyword: "",
      //入力されたポイント数
      Managepoint: null,
      thanks_message: "",
      //確定時のダイアログ表示用
      pointassignmentConfirmationDialog: false,
      //送信失敗ダイアログ
      sendfailedDialog: false,
      //ポイント付与先の確認用
      isdest_check: false,

      //mongoへポイント付与処理まで完了フラグ
      isPointAssignment: false,
      //mongoへポイント付与履歴更新まで処理完了フラグ
      isAssignment_history: false,
      //ロードのアニメーション
      loading_overlay: false,

      //バリデーションチェック
      valid: false,
      pointRule: [
        (text) => String(text).match(/^-?[0-9]*$/) != null || "数字を入力してください",
      ],
    };
  },
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("admin")).data;
    if (!user) {
      console.log("未ログイン。ログイン画面へ遷移");
      this.$router.push("/adminsignin");
      return;
    }
    if (user.auth === "not_auth_user") {
      console.log("ユーザ権限無し");
      this.$router.push("/adminNotAuth");
    }
  },
  created () {
    // キャッシュ削除
    if (process.env.NODE_ENV === "production") {
      window.navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      caches.keys().then((keys) => {
        keys.forEach((cacheName) => {
          if (cacheName) {
            caches.delete(cacheName);
          }
        });
      });
    }
  },
  methods: {
    async SearchMember (serchInput) {
      console.log("valid" + this.valid);
      //送信先検索処理はメール、社番、氏名を結合した値に対して、前方一致で行われる。
      let response = await Methods.membersearch("admin", serchInput);
      if (response.data === "searchkeyword_null") {
        response.data = [];
        this.SearchResult = [];
      }
      this.SearchResult = response.data;
    },

    //mongoDB 側のポイント処理
    async put_point () {
      try {
        const user = (await Methods.getAuthUserInfo("admin")).data;
        if (!user) {
          console.log("ログイン画面へ遷移");
          this.$router.push("/adminsignin");
          return;
        }
        if (user.auth === "not_auth_user") {
          console.log("ユーザ権限無し");
          this.$router.push("/adminNotAuth");
          return;
        }

        this.pointassignmentConfirmationDialog = false;
        this.loading_overlay = true;

        //userは管理者
        //earnUserNumはポイントを付与される人
        let params = {};
        params.useUserNum = user.accountnumber;
        params.earnUserNum = this.PointAssignmentMember.accountnumber;
        params.message = this.thanks_message;
        params.point = -this.Managepoint;
        let adminres = await Methods.givepoint("admin", params);

        console.log("adminres" + adminres);

        //バックエンドからの戻り値を見て後続処理を実行する
        if (adminres.data.code === 1) {
        //入力エラー
          this.loading_overlay = false;
          this.pointassignmentConfirmationDialog = true;
          this.isdest_check = false;
          return;
        } else if (adminres.data.code === -1) {
          console.log("正常に通信が終了しなかった");
          //通信が異常終了時のエラー処理
          this.loading_overlay = false;
          swal({
            title: "ポイント割り当て処理が失敗しました",
            text: "お手数ですが、再度送信をお試し\nください。",
            icon: "error",
            button: "閉じる",
          });
          return;
        } else if (adminres.data.code === 2) {
          //ポイント不足の場合
          this.loading_overlay = false;
          swal({
            title: "ポイント割り当て処理が失敗しました",
            text: "ユーザーのポイントが不足しています。",
            icon: "error",
            button: "閉じる",
          });
          return;
        } else {
          console.log("ポイント送信処理が正常終了");
          this.isPointAssignment = true;
          this.isAssignment_history = true;
        }

        // 受信者にメール送信を行う
        if (this.isPointAssignment && this.isAssignment_history) {
          // 送信エラーとなった場合でもエラーメッセージ等は出さない
          await Methods.sendmail("admin", {
            earnUser: this.PointAssignmentMember,
            sendUser: { membername: "管理者" },
            type: "point",
          });
        }

        this.loading_overlay = false;
        swal({
          title: "ポイント割り当て処理が完了！！",
          text: "処理はすべて正常に完了しました。",
          icon: "success",
          button: "閉じる",
        });
        this.isPointAssignment = false;
        this.isAssignment_history = false;
        this.PointAssignmentMember = null;
        this.SearchResult = [];
        this.Managepoint = null;
        this.thanks_message = "";
      } catch (err) {
        // 送信エラーとなった場合でもエラーメッセージ等は出さない
        await Methods.senderrmail("admin", {
          error: err,
        });
        this.loading_overlay = false; //オーバレイをOFF
        this.sendfailedDialog = true; //送信失敗ダイアログをON
        this.isPointAssignment = false;
        this.isAssignment_history = false;
        //入力内容初期化
        this.PointAssignmentMember = null; //選択した送信先情報のクリア
        this.SearchResult = []; //検索結果エリアのクリア
        this.Managepoint = null;
        this.thanks_message = "";
      }
    },

    PointAssignmentDialog () {
      //宛先未入力かどうかのnullチェック
      if (!this.PointAssignmentMember) {
        this.isdest_check = false;
        return;
        //ここでリターンしないと次の処理でaccountnumberが参照できずエラーになるため処理を書く
      }
      if (!this.PointAssignmentMember.membername) {
        this.isdest_check = false;
      } else {
        console.log(
          "PointAssignmentMemberの中身は：",
          this.PointAssignmentMember
        );
        this.isdest_check = true;
        this.PointAssignmentMembername = this.PointAssignmentMember.membername;
      }
    },
  },
};
</script>
<style></style>
