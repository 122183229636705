<template>
  <v-row justify="end">
    <v-dialog
      v-model="dialog"
      persistent
      width="90%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          v-bind:class="[ isSearch ? 'v-chip--active' : '' ,'v-chip--no-color v-chip--outlined theme--light' ]"
          outlined
        >
          <v-icon v-if="isSearch" left>mdi-check</v-icon>
          詳細検索
        </v-chip>
      </template>
      <v-card>
        <v-toolbar color="primary" flat>
          <v-toolbar-title class="font-weight-bold white--text">
            <span class="text-h8">検索条件</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-col
          cols="12"
          class="py-0 pr-0"
        >
        <v-container>
          <v-card-title v-if="searchType==='FROM'" class="pa-0 mb-1">FROM</v-card-title>
          <v-card-title v-else-if="searchType==='TO'" class="pa-0 mb-1">TO</v-card-title>
          <AppComboboxMultiple
            :label="
              '検索対象を選択(' +
              searchMemberSelectMax +
              '人まで可)'
            "
            :model.sync="inputCond.users"
            :search-input.sync="searchMemberCond"
            :search-func="searchMember"
            :search-results.sync="searchMemberList"
            item-text="membername"
            first-item-text="department"
            second-item-text="membername"
            separate-text=" / "
            item-sub-text="mail"
            item-identifier="accountnumber"
            :select-max="searchMemberSelectMax"
            ref="searchComboBox"
          ></AppComboboxMultiple>
        <v-card-title class="pa-0 mb-1 mt-5">年月</v-card-title>
        <v-row justify="center" dense>
          <v-col
            cols="4"
            class="py-0 pr-0"
          >
            <v-select
              :items="year"
              v-model="inputCond.fromYear"
              label=""
              outlined
            ></v-select>
          </v-col>
          <v-col
            cols="1"
            class="py-0 pr-0 mt-8"
          >
            <span>年</span>
          </v-col>
          <v-col
            cols="4"
            class="py-0 pr-0"
          >
            <v-select
              :items="month"
              v-model="inputCond.fromMonth"
              label=""
              outlined
            ></v-select>
          </v-col>
          <v-col
            cols="1"
            class="py-0 pr-7 mt-8"
          >
            <span>月</span>
          </v-col>
        </v-row>
        <v-row justify="center" dense>
          <v-col
            cols="1"
            class="py-0 pr-0 mt-5"
          >
            <span>～</span>
          </v-col>
          <v-col
            cols="4"
            class="py-0 pr-0"
          >
            <v-select
              :items="year"
              v-model="inputCond.toYear"
              label=""
              outlined
              hide-details
            ></v-select>
          </v-col>
          <v-col
            cols="1"
            class="py-0 pr-0 mt-8"
          >
            <span>年</span>
          </v-col>
          <v-col
            cols="4"
            class="py-0 pr-0"
          >
            <v-select
              :items="month"
              v-model="inputCond.toMonth"
              label=""
              outlined
              hide-details
            ></v-select>
          </v-col>
          <v-col
            cols="1"
            class="py-0 pr-7 mt-8"
          >
            <span>月</span>
          </v-col>
          </v-row>
        </v-container>
        </v-col>
        <v-card-actions>
          <v-btn v-on:click="clearSearch"
            color="blue darken-1"
            text
          >
            検索解除
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-on:click="search"
            color="blue darken-1"
            text
          >
            検索
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AppComboboxMultiple from "@/components/AppComboboxMultiple";
import Methods from "@/api/methods";
const SELECT_PAST_YEAR = 5;

export default {
  props: {
    searchType: String
  },
  components: {
    AppComboboxMultiple,
  },
  data: () => ({
    dialog: false,
    year: [],
    month: ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    inputCond: {
      users: [],
      fromYear: "",
      fromMonth: "",
      toYear: "",
      toMonth: ""
    },
    isSearch: false,
    buttonColor: "primary",
    // From/To検索対象最大選択数
    searchMemberSelectMax: 5,
    // From/Toの検索条件(前方一致)
    searchMemberCond: "",
    // From/Toの検索結果
    searchMemberList: [],
  }),
  async mounted () {
    // 年月設定
    let thisYear = new Date().getFullYear();
    // 未選択用の空文字を追加
    this.year.push("");
    // 過去年分設定
    for (let i = 0; i < SELECT_PAST_YEAR; i++) {
      this.year.push(thisYear.toString());
      thisYear--;
    }
  },
  methods: {
    search () {
      // 検索条件が指定されている場合は検索ボタンにアイコンを表示する
      if (this.inputCond.users.length > 0 || this.inputCond.fromYear !== "" ||
      this.inputCond.fromMonth !== "" || this.inputCond.toYear !== "" || this.inputCond.toMonth !== "") {
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }

      this.dialog = false;
      this.$emit("searchHistory", this.searchType, this.inputCond);
    },
    clearSearch () {
      // 検索条件を初期化し、マイページに渡す
      this.inputCond = {
        users: [],
        fromYear: "",
        fromMonth: "",
        toYear: "",
        toMonth: "",
      };
      this.isSearch = false;
      this.dialog = false;
      this.$emit("searchHistory", this.searchType, this.inputCond);
    },
    async searchMember (serchCond) {
      //送信先検索処理はメール、社番、氏名を結合した値に対して、前方一致で行われる。
      let response = await Methods.membersearch("user", serchCond);
      if (response.data === "searchkeyword_null") {
        response.data = [];
      }
      this.searchMemberList = response.data;
    },
  }
};
</script>

<style scoped>

</style>
