<template>
  <v-app>
    <shareheader />
    <router-view></router-view>
    <sharefooter />
  </v-app>
</template>
<script>
import Shareheader from "@/components/Shareheader";
import Sharefooter from "@/components/Sharefooter";

export default {
  name: "app",
  components: {
    Shareheader,
    Sharefooter,
  },
};
</script>
