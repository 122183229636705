import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import VueLoading from "vue-loading-template";
import VuePapaParse from "vue-papa-parse";

Vue.config.productionTip = false;
Vue.use(VueLoading /** options **/);
Vue.use(VuePapaParse);

new Vue({
  el: "#app",
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
