<template>
  <div class="top">
    <v-app id="inspire">
      <v-main>
        <v-container fluid class="pt-0">
          <v-row justify="center my-0">
            <!-- 左よせ justify="start"-->
            <v-toolbar-title class="font-weight-bold">
              <v-row justify="center ma-0">
                <v-col
                  class="mb-0 pb-0 mt-0 pt-0 font-weight-bold"
                  style="font: size 0.5em"
                  >残 {{ loginUserPoint }}pt
                </v-col>
              </v-row>
            </v-toolbar-title>
          </v-row>

          <v-card>
            <v-tabs v-model="tab" background-color="primary" dark grow class="">
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab
                v-for="item in tabitems"
                :key="item.tab"
                class="font-weight-bold"
                >{{ item.tab }}</v-tab
              >
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tabitems" :key="item.tab">
                <v-card>
                  <v-card-text class="pb-0">{{ item.content }}</v-card-text>
                  <!--サンクスギフト画面-->
                  <div v-if="item.tab === 'サンクスギフト'">
                    <!--サンクスギフト送信先を検索-->
                    <div id="thanksgift">
                      <v-container py-0>
                        <AppComboboxMultiple
                          :label="
                            '贈り先を選択(' +
                            giftSendSelectMax +
                            '人まで可)'
                          "
                          :model.sync="earnCoinMemberMultiple"
                          :search-input.sync="thankscardDestination"
                          :search-func="searchthankscardDestination"
                          :search-results.sync="sendCandidate"
                          item-text="membername"
                          first-item-text="department"
                          second-item-text="membername"
                          separate-text=" / "
                          item-sub-text="mail"
                          item-identifier="accountnumber"
                          :select-max="giftSendSelectMax"
                          ref="searchComboBox"
                        ></AppComboboxMultiple>
                      </v-container>
                    </div>
                    <v-container pt-0>
                      <v-row
                        justify="center"
                        align-content="center"
                        no-gutters
                        class="myx-3 pt-3"
                      >
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/arigato.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('arigato')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/maemuki.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('maemuki')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/tasukatta.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('tasukatta')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/yoroshiku.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('yoroshiku')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/omedeto.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('omedeto')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/niceidea.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('niceidea')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/sugoine.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('sugoine')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/shigotogahayai.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('shigotogahayai')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/kando.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('kando')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/goodjob.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('goodjob')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/batontouch.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('batontouch')"
                          >
                          </v-card>
                        </v-col>
                        <v-col cols="4">
                          <v-card
                            :img="require('@/assets/ooda.png')"
                            height="100"
                            width="100"
                            class="mx-auto"
                            @click="openThanksGiftDialog('ooda')"
                          >
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-card>

                <v-dialog
                  v-model="giftSendConfirmationDialog"
                  persistent
                  max-width="400"
                >
                  <v-card height="auto" max-width="auto" shaped>
                    <v-row justify="center">
                      <v-col></v-col>
                      <v-col>
                        <v-img
                          height="150"
                          width="150"
                          v-bind:src="require(`@/assets/${giftimage}.png`)"
                        ></v-img>
                      </v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-card-title class="headline"
                        >ギフトを贈りますか？</v-card-title
                      >
                    </v-row>
                    <v-card-text>
                      <li
                        style="list-style: none"
                        v-for="giftDest in earnCoinMemberMultiple"
                        :key="giftDest.accountnumber"
                      >
                        {{ giftDest.membername }}さん
                      </li>
                      にギフトを贈ります
                    </v-card-text>

                    <v-textarea
                      class="mx-7"
                      v-model="thanks_message_gift"
                      label="コメント（任意）"
                      rows="1"
                      v-bind:rules="giftcommentrule"
                      maxlength="60"
                      outlined
                      auto-grow
                    ></v-textarea>
                    <!-- v-bind:rules="myrules" -->
                    <!-- maxlength="15" -->
                    <v-card-actions>
                      <v-row>
                        <v-col>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="thanksGiftSend(gifttype)"
                            >はい</v-btn
                          >
                        </v-col>
                        <v-col>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeGiftSendDialog()"
                            >いいえ</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-overlay :value="overlay">
                  <VueLoading
                    type="spiningDubbles"
                    color="white"
                    :size="{ width: '150px', height: '150px' }"
                  ></VueLoading>
                  <span class="font-weight-bold" style="font-size: 2em">
                    now sending...
                  </span>
                </v-overlay>

                <v-dialog v-model="sendGiftSuccessDialog" max-width="400">
                  <v-card height="auto" max-width="auto" shaped>
                    <v-row justify="center">
                      <v-col></v-col>
                      <v-col>
                        <v-img
                          height="150"
                          width="150"
                          src="@/assets/getcoin.png"
                        ></v-img
                      ></v-col>
                      <v-col></v-col>
                      <v-card-title>
                        <div class="body-1 mb-1 text--primary">
                          <br />{{ sendpoint }}coin 贈呈しました！
                        </div>
                      </v-card-title>
                      <v-card-text v-if="!isenoughPoint" class="red--text">
                        所持ポイントが不足していたため、<br />送信先にコインは追加されません。
                      </v-card-text>
                      <v-card-text v-if="!isdifferentCompany">
                        グループ他社の仲間へは<br />コインは追加されません。
                      </v-card-text>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="sendGiftSuccessDialog = false"
                        >閉じる</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="wrongDestinationDialog" max-width="400">
                  <v-card height="auto" max-width="auto" shaped>
                    <v-row justify="center">
                      <v-col></v-col>
                      <v-col>
                        <v-img
                          height="150"
                          width="150"
                          src="@/assets/send_failed.png"
                        ></v-img
                      ></v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-card-text>
                      <div class="body-1 mb-1 text--primary">
                        <br />送信先が未選択、
                        <br />もしくは自分自身に設定されているため、
                        <br />送信先を選び直して下さい。
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="wrongDestinationDialog = false"
                        >閉じる</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="notEnoughPointsDialog" max-width="400">
                  <v-card height="auto" max-width="auto" shaped>
                    <v-row justify="center">
                      <v-col></v-col>
                      <v-col>
                        <v-img
                          height="150"
                          width="150"
                          src="@/assets/send_failed.png"
                        ></v-img
                      ></v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-card-text>
                      <div class="body-1 mb-1 text--primary">
                        <br />ポイントが足りません。
                        <br />贈り先を選び直してください。
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="notEnoughPointsDialog = false"
                        >閉じる</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="sendmongofailedDialog" max-width="400">
                  <v-card>
                    <v-card-title class="headline">
                      送信失敗・・・
                    </v-card-title>
                    <v-divider class="mx-3"></v-divider>
                    <v-card-text>
                      <div class="body-1 mb-1 text--primary">
                        <br />送信中にエラーが発生しました。
                        <br />お手数ですが、再度送信を <br />お試しください。
                      </div>
                    </v-card-text>
                    <v-row justify="center">
                      <v-col></v-col>
                      <v-col>
                        <v-img
                          height="150"
                          width="150"
                          src="@/assets/send_failed.png"
                        ></v-img>
                      </v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="sendmongofailedDialog = false"
                        >閉じる</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="sendfailedDialog" max-width="400">
                  <v-card>
                    <v-card-title class="headline">
                      送信失敗・・・
                    </v-card-title>
                    <v-divider class="mx-3"></v-divider>
                    <v-card-text>
                      <div class="body-1 mb-1 text--primary">
                        <br />送信中にエラーが発生しました。
                        <br />再送信せず、お手数ですが
                        <br />管理者にお問い合わせください。
                      </div>
                    </v-card-text>
                    <v-row justify="center">
                      <v-col></v-col>
                      <v-col>
                        <v-img
                          height="150"
                          width="150"
                          src="@/assets/send_failed.png"
                        ></v-img>
                      </v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="sendfailedDialog = false"
                        >閉じる</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!--サンクスギフト画面ここまで-->
                <!-- TODO:v-form使って入力値のバリデーションを行う -->
                <v-card>
                  <div v-if="item.tab === 'サンクスカード'">
                    <div id="thankscard" class="my-n3">
                      <v-container>
                        <v-card-title>サンクスカード送信先</v-card-title>
                        <AppCombobox
                          label="贈り先を選択"
                          :model.sync="earnCoinMember"
                          :search-input.sync="thankscardDestination"
                          :search-func="searchthankscardDestination"
                          :search-results.sync="sendCandidate"
                          item-text="membername"
                          first-item-text="department"
                          second-item-text="membername"
                          separate-text=" / "
                          item-sub-text="mail"
                          item-identifier="accountnumber"
                          single
                          :rules="[required]"
                        ></AppCombobox>
                      </v-container>
                    </div>
                    <v-divider class="mx-3"></v-divider>

                    <div id="philosophy" class="my-n3">
                      <v-container>
                        <v-card-title>JALフィロソフィ</v-card-title>
                        <!-- item-textは画面にレンダリングされる値、item-valueはDOM取得される値 -->
                        <v-combobox
                          v-model="selectedPhilosophy"
                          :items="dropitems"
                          item-text="contents"
                          item-value="contents"
                          :value="selectedPhilosophy"
                          outlined
                          label="検索（一番近いフィロソフィを選んで下さい）"
                          prepend-icon="mdi-database-search"
                        ></v-combobox>
                      </v-container>
                    </div>
                    <v-divider></v-divider>

                    <div id="thankscomment" class="my-n3">
                      <v-container>
                        <v-card-title>メッセージ</v-card-title>
                        <v-textarea
                          v-model="thanks_message"
                          label="贈りたい方へのコメントを入力して下さい"
                          prepend-icon="mdi-pen"
                          outlined
                          auto-grow
                        ></v-textarea>
                      </v-container>
                    </div>

                    <div id="cardsendbutton">
                      <v-dialog
                        v-model="cardSendConfirmationDialog"
                        persistent
                        max-width="400"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="openThanksCardDialog"
                          >
                            カードを送る
                            <v-icon right>mdi-gift-outline</v-icon>
                          </v-btn>
                          <v-col></v-col>
                        </template>
                        <v-card
                          height="auto"
                          max-width="auto"
                          v-if="isdest_check"
                          shaped
                        >
                          <v-row justify="center">
                            <v-col></v-col>
                            <v-col>
                              <v-img
                                height="150"
                                width="150"
                                v-bind:src="
                                  require('@/assets/thankscard_conform.png')
                                "
                              ></v-img>
                            </v-col>
                            <v-col></v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-card-title class="headline"
                              >カードを贈りますか？</v-card-title
                            >
                          </v-row>
                          <v-card-text>
                            {{ earnCoinMembername }}さんにカードを贈ります
                          </v-card-text>
                          <v-card-actions>
                            <v-row>
                              <v-col>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="thanksCardSend"
                                  >はい</v-btn
                                >
                              </v-col>
                              <v-col>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="cardSendConfirmationDialog = false"
                                  >いいえ</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>

                        <v-card v-else height="auto" max-width="auto" shaped>
                          <v-row justify="center">
                            <v-col></v-col>
                            <v-col>
                              <v-img
                                height="150"
                                width="150"
                                src="@/assets/send_failed.png"
                              ></v-img
                            ></v-col>
                            <v-col></v-col>
                          </v-row>
                          <v-card-text
                            class="body-1 mb-1 text--primary"
                            style="white-space: pre-line"
                          >
                            {{ cardInputErr }}
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="cardSendConfirmationDialog = false"
                              >閉じる</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>

                    <v-dialog v-model="sendSuccessDialog" max-width="400">
                      <v-card height="auto" max-width="auto" shaped>
                        <v-row justify="center">
                          <v-col></v-col>
                          <v-col>
                            <v-img
                              height="150"
                              width="150"
                              src="@/assets/getcoin.png"
                            ></v-img
                          ></v-col>
                          <v-col></v-col>
                          <v-card-title>
                            <div class="body-1 mb-1 text--primary">
                              <br />{{ sendpoint }}coin 贈呈しました！
                            </div>
                          </v-card-title>
                          <v-card-text v-if="!isenoughPoint" class="red--text">
                            所持ポイントが不足していたため、<br />送信先にコインは追加されません。
                          </v-card-text>
                          <v-card-text v-if="!isdifferentCompany">
                            グループ他社の仲間へは<br />コインは追加されません。
                          </v-card-text>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="sendSuccessDialog = false"
                            >閉じる</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="sendmongofailedDialog" max-width="400">
                      <v-card>
                        <v-card-title class="headline">
                          送信失敗・・・
                        </v-card-title>
                        <v-divider class="mx-3"></v-divider>
                        <v-card-text>
                          <div class="body-1 mb-1 text--primary">
                            <br />送信中にエラーが発生しました。
                            <br />お手数ですが、再度送信を
                            <br />お試しください。
                          </div>
                        </v-card-text>
                        <v-row justify="center">
                          <v-col></v-col>
                          <v-col>
                            <v-img
                              height="150"
                              width="150"
                              src="@/assets/send_failed.png"
                            ></v-img>
                          </v-col>
                          <v-col></v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="sendmongofailedDialog = false"
                            >閉じる</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="sendfailedDialog" max-width="400">
                      <v-card>
                        <v-card-title class="headline">
                          送信失敗・・・
                        </v-card-title>
                        <v-divider class="mx-3"></v-divider>
                        <v-card-text>
                          <div class="body-1 mb-1 text--primary">
                            <br />送信中にエラーが発生しました。
                            <br />再送信せず、お手数ですが
                            <br />管理者にお問い合わせください。
                          </div>
                        </v-card-text>
                        <v-row justify="center">
                          <v-col></v-col>
                          <v-col>
                            <v-img
                              height="150"
                              width="150"
                              src="@/assets/send_failed.png"
                            ></v-img>
                          </v-col>
                          <v-col></v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="sendfailedDialog = false"
                            >閉じる</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import philosophies from "../assets/philosophy.json";
import Methods from "@/api/methods";
import AppCombobox from "@/components/AppCombobox";
import AppComboboxMultiple from "@/components/AppComboboxMultiple";
import { VueLoading } from "vue-loading-template";

let thanksCardPoint = 50;
let thanksGiftPoint = 10;

let loginUserNumber = "";
let earnUserNumber = "";

export default {
  props: {
    source: String,
    earnCoinMemberProps: Array,

  },
  components: {
    AppCombobox,
    AppComboboxMultiple,
    VueLoading,
  },
  data: () => ({
    drawer: null,
    tab: null,
    tabitems: [
      { tab: "サンクスギフト", content: "" },
      { tab: "サンクスカード", content: "" },
    ],

    // ユーザー情報に関する初期値およびルールの定義------------------------------------------------
    //ログインユーザの送信可能ポイント
    loginUserPoint: 0,

    // サンクスギフトに関する初期値およびルールの定義------------------------------------------------

    //サンクスギフト送信確認ダイアログの初期表示フラグ
    giftSendConfirmationDialog: false,
    //サンクスギフト送信成功ダイアログの初期表示フラグ
    sendGiftSuccessDialog: false,
    //サンクスギフト送信時のコメント文字数制限
    giftcommentrule: [(text) => text.length <= 60 || "最大文字数は60文字です"],

    // サンクスカードに関する初期値およびルールの定義------------------------------------------------

    //サンクスカード贈り先を検索するためのキー初期値
    selectSerchKey: "useMail",
    //サンクスカード送信先相手の検索条件(前方一致)
    thankscardDestination: "",
    //サンクスカード送信先相手検索結果のレスポンス
    sendCandidate: [],
    earnCoinMember: null,
    //サンクスカード送信時のダイアログに出力される贈り先の氏名
    earnCoinMembername: "",
    //サンクスギフト送信先配列
    earnCoinMemberMultiple: [],
    //サンクスギフト送信先最大選択数
    giftSendSelectMax: 5,
    //サンクスカード送信確認ダイアログの初期表示フラグ
    cardSendConfirmationDialog: false,
    //サンクスカード送信成功ダイアログの初期表示フラグ
    sendSuccessDialog: false,
    //フィロソフィー選択時のドロップダウン用初期値設定
    select: null,
    dropitems: [],
    philosophies,
    cardInputErr: "",
    cardInputErrSendUser: "\n送信先が未選択、\nもしくは自分自身に設定されているため、\n送信先を選び直して下さい。",
    cardInputErrPhiloMsg: "\nJALフィロソフィを選択、\nまたはメッセージを入力\nしてください。",

    // サンクスギフト、カード共通の初期値およびルールの定義-------------------------------------------

    //処理中ローディング画面の初期表示フラグ
    overlay: false,

    //サンクスギフトカード送信失敗ダイアログ(mongo完了前)の初期表示フラグ
    sendmongofailedDialog: false,
    //サンクスギフトカード送信失敗ダイアログの初期表示フラグ
    sendfailedDialog: false,
    //送信先が自分自身に設定されている場合のアラートダイアログ
    wrongDestinationDialog: false,
    //送信先の選択数に対して所持ポイントが足りない場合のアラートダイアログ
    notEnoughPointsDialog: false,
    required: (value) => !!value || "贈り先を選択してください", // 入力必須の制約
    isdestination: true,
    //ログインしているユーザID
    // loginUserID: "",
    //ポイント渡し処理の成否判定
    isusepoint: false,
    isusepointhistory: false,
    isearncoin: false,
    isearncoinhistory: false,
    isenoughPoint: true,
    isdifferentCompany: true,
    thanks_message: "",
    thanks_message_gift: "",
    selectedPhilosophy: "",
    isdest_check: false,
    sendpoint: 0,
    logonUserInfo: {},
    giftimage: "thankscard",
  }),
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("user")).data;
    if (!user) {
      console.log("ログイン画面へ遷移");
      this.$router.push("/");
      return;
    }
    if (user.auth === "not_auth_user") {
      console.log("ユーザ権限無し");
      this.$router.push("/notAuth");
      return;
    }
    this.user = user;
    console.log("ユーザ情報は：", JSON.stringify(user));
  },
  created () {
    // キャッシュ削除
    if (process.env.NODE_ENV === "production") {
      window.navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      caches.keys().then((keys) => {
        keys.forEach((cacheName) => {
          if (cacheName) {
            caches.delete(cacheName);
          }
        });
      });
    }
    //画面描画時にjsonファイルからフィロソフィを読み込む
    const allphilosophy = [];

    philosophies.forEach((philosophy) => {
      const item = {
        contents: philosophy.contents,
      };
      allphilosophy.push(item);
    });
    this.dropitems = allphilosophy;
    //ログイン者情報取得＆トークンをlocalstrageに格納
    this.idget();
    console.log("earnCoinMemberProps:" + JSON.stringify(this.earnCoinMemberProps));
    if (this.earnCoinMemberProps !== undefined) {
      this.earnCoinMemberMultiple = this.earnCoinMemberProps;
      console.log("earnCoinMemberMultiple:" + this.earnCoinMemberMultiple);
    }
  },
  updated () {
    //同一コンポーネント内のmethods内のメソッドを出す場合はthisで呼べる
    // this.idget();
  },
  computed: {},
  watch: {},
  methods: {
    //nameanddep: item => item.membername + ' — ' + item.department,
    idget: async function () {
      let empMember = (await Methods.getAuthUserInfo("user")).data;
      loginUserNumber = empMember.accountnumber; //mongoDB側、ブロックチェーン側双方で使用するためグローバル変数に格納
      this.loginUserPoint = empMember.point; //今月送信可能ポイント取得
    },
    async searchthankscardDestination (serchInput) {
      //送信先検索処理はメール、社番、氏名を結合した値に対して、前方一致で行われる。
      let response = await Methods.membersearch("user", serchInput);
      if (response.data === "searchkeyword_null") {
        response.data = [];
      }
      this.sendCandidate = response.data;
    },
    //ギフトとカードで確認ダイアログを共通化する
    async openThanksGiftDialog (type) {
      //宛先未選択かどうかのlengthチェック
      if (this.earnCoinMemberMultiple.length === 0) {
        this.wrongDestinationDialog = true;
        return;
        //ここでリターンしないと次の処理でaccountnumberが参照できずエラーになるため処理を書く
      }
      //自分(ログイン中のユーザー)が選択されていないかチェック
      const checkSelectLoginUser = this.earnCoinMemberMultiple.filter(
        (item) => item.accountnumber === loginUserNumber
      );
      if (checkSelectLoginUser.length > 0) {
        this.wrongDestinationDialog = true;
        return;
      }
      // 所持ポイントが10以上の場合、人数 * 10ポイントが足りているかチェック
      // (10未満の場合はコイン0で送信先に送信)
      // 現在の所持ポイントを取得
      const memberInfo = (await Methods.getAuthUserInfo("user")).data;
      const nowPoint = memberInfo.point;
      const loginUserCompany = memberInfo.company;
      const earnCoworker = this.earnCoinMemberMultiple.filter((item) => item.company === loginUserCompany);
      if (nowPoint >= 10) {
        if (earnCoworker.length * 10 > nowPoint) {
          this.notEnoughPointsDialog = true;
          return;
        }
      }

      this.thanks_message_gift = "";
      this.giftimage = type;
      this.giftSendConfirmationDialog = true;
      this.gifttype = type;
    },
    openThanksCardDialog () {
      //宛先未入力かどうかのnullチェック
      if (!this.earnCoinMember) {
        this.cardInputErr = this.cardInputErrSendUser;
        this.isdest_check = false;
        return;
        //ここでリターンしないと次の処理でaccountnumberが参照できずエラーになるため処理を書く
      }
      if (
        this.earnCoinMember.accountnumber === loginUserNumber ||
        !this.earnCoinMember.membername
      ) {
        this.cardInputErr = this.cardInputErrSendUser;
        this.isdest_check = false;
      } else if (!this.thanks_message && !this.selectedPhilosophy.contents) {
        this.cardInputErr = this.cardInputErrPhiloMsg;
        this.isdest_check = false;
      } else {
        console.log("earnCoinMemberの中身は：", this.earnCoinMember);
        this.isdest_check = true;
        console.log("選択したフィロソフィは", this.selectedPhilosophy.contents);
        this.earnCoinMembername = this.earnCoinMember.membername;
        earnUserNumber = this.earnCoinMember.accountnumber; //mongoDB側、ブロックチェーン側双方で使用するためグローバル変数に格納
      }
    },
    async thanksGiftSend (thanksGiftType) {
      try {
        this.giftSendConfirmationDialog = false; //画面のダイアログ表示フラグをOFF
        this.overlay = true; //オーバレイをON

        //ユーザログイン確認処理
        const user = (await Methods.getAuthUserInfo("user")).data;
        if (!user) {
          console.log("ログイン画面へ遷移");
          this.$router.push("/");
          return;
        }
        if (user.auth === "not_auth_user") {
          console.log("ユーザ権限無し");
          this.$router.push("/notAuth");
          return;
        }

        this.isusepoint = false; //ポイント送信処理成功フラグ
        this.isearncoin = false; //ポイント送信履歴更新処理フラグ
        this.isenoughPoint = true; //ポイント足りているかフラグ
        this.isdifferentCompany = true; //会社同じかフラグ

        // 送信先情報設定
        let userInfo = [];
        for (const earnUser of this.earnCoinMemberMultiple) {
          userInfo.push({
            useUserNum: loginUserNumber,
            earnUserNum: earnUser.accountnumber,
            message: this.thanks_message_gift,
            picturetype: thanksGiftType,
          });
        }

        let useresponse = await Methods.thanksgiftsend("user", userInfo);
        console.log(useresponse);

        //バックエンドからの戻り値を見て後続処理を実行する
        if (useresponse.data.code === 1) {
          //入力エラー
          this.overlay = false;
          this.wrongDestinationDialog = true;
          this.isdest_check = false;
          return;
        } else if (
          useresponse.data.code === 0 &&
          useresponse.data.point === "lack"
        ) {
          //ポイント不足の場合、
          //処理完了時のダイアログに注意書きを表示するようフラグ立て
          this.isenoughPoint = false;
          this.isusepoint = true;
          this.isearncoin = true;
        } else if (
          useresponse.data.code === 0 &&
          useresponse.data.status === "companydifferent"
        ) {
          //会社が異なるの場合、
          //処理完了時のダイアログに注意書きを表示するようフラグ立て
          this.isdifferentCompany = false;
          this.isusepoint = true;
          this.isearncoin = true;
        } else if (useresponse.data.code === -1) {
          console.log("サンクスギフト送信処理異常終了(mongo処理完了前)");
          this.overlay = false;
          this.sendmongofailedDialog = true;
          return;
        } else {
          console.log("サンクスギフト送信処理正常終了");
          this.isusepoint = true;
          this.isearncoin = true;
          this.idget();
        }

        // 受信者にメール送信を行う
        if (this.isusepoint && this.isearncoin) {
          for (const earnUser of this.earnCoinMemberMultiple) {
            // 送信エラーとなった場合でもエラーメッセージ等は出さないため、
            // 待つ必要がないのでawaitせずに処理を継続する
            Methods.sendmail("user", {
              earnUser,
              sendUser: user,
              type: "gift",
            });
          }
        }

        this.sendpoint = thanksGiftPoint;
        this.overlay = false;
        this.sendGiftSuccessDialog = true;
        this.isusepoint = false;
        this.isearncoin = false;

        //入力内容初期化
        this.earnCoinMemberMultiple = []; //選択した送信先情報のクリア
        this.sendCandidate = []; //検索結果エリアのクリア
        this.thanks_message_gift = ""; //コメントの初期化
      } catch (err) {
        this.giftSendConfirmationDialog = false; //画面のダイアログ表示フラグをOFF
        this.overlay = false; //オーバレイをOFF
        this.sendfailedDialog = true; //送信失敗ダイアログをON
        this.isusepoint = false;
        this.isearncoin = false;
        //入力内容初期化
        this.earnCoinMemberMultiple = []; //選択した送信先情報のクリア
        this.sendCandidate = []; //検索結果エリアのクリア
        this.thanks_message_gift = ""; //コメントの初期化

        // 送信エラーとなった場合でもエラーメッセージ等は出さない
        await Methods.senderrmail("user", {
          error: err,
        });
      }
    },
    closeGiftSendDialog () {
      this.thanks_message_gift = "";
      this.giftSendConfirmationDialog = false;
    },
    async thanksCardSend () {
      try {
        this.cardSendConfirmationDialog = false;
        this.overlay = true;

        const user = (await Methods.getAuthUserInfo("user")).data;
        if (!user) {
          console.log("ログイン画面へ遷移");
          this.$router.push("/");
          return;
        }
        if (user.auth === "not_auth_user") {
          console.log("ユーザ権限無し");
          this.$router.push("/notAuth");
          return;
        }

        this.isusepoint = false;
        this.isearncoin = false;
        this.isenoughPoint = true;
        this.isdifferentCompany = true;

        let userInfo = {};
        userInfo.useUserNum = loginUserNumber;
        userInfo.earnUserNum = earnUserNumber;
        userInfo.message = this.thanks_message;
        userInfo.philosophy = this.selectedPhilosophy.contents;

        let useresponse = await Methods.thankscardsend("user", userInfo);
        console.log(useresponse);

        //バックエンドからの戻り値を見て後続処理を実行する
        if (useresponse.data.code === 1) {
          //入力エラー
          this.overlay = false;
          this.wrongDestinationDialog = true;
          this.isdest_check = false;
          return;
        } else if (
          useresponse.data.code === 0 &&
          useresponse.data.point === "lack"
        ) {
          //ポイント不足の場合、
          //処理完了時のダイアログに注意書きを表示するようフラグ立て
          this.isenoughPoint = false;
          this.isusepoint = true;
          this.isearncoin = true;
        } else if (
          useresponse.data.code === 0 &&
          useresponse.data.status === "companydifferent"
        ) {
          //会社が異なるの場合、
          //処理完了時のダイアログに注意書きを表示するようフラグ立て
          this.isdifferentCompany = false;
          this.isusepoint = true;
          this.isearncoin = true;
        } else if (useresponse.data.code === -1) {
          console.log("サンクスカード送信処理異常終了(mongo処理完了前)");

          this.overlay = false;
          this.sendmongofailedDialog = true;
          return;
        } else if (useresponse.data.code === -2) {
          console.log("サンクスカード送信処理異常終了");
          this.idget();
          this.overlay = false;
          this.sendfailedDialog = true;
          return;
        } else {
          console.log("サンクスカード送信処理正常終了");
          this.isusepoint = true;
          this.isearncoin = true;
          this.idget();
        }

        // 受信者にメール送信を行う
        if (this.isusepoint && this.isearncoin) {
          // 送信エラーとなった場合でもエラーメッセージ等は出さない
          await Methods.sendmail("user", {
            earnUser: this.earnCoinMember,
            sendUser: this.user,
            type: "card",
          });
        }

        this.sendpoint = thanksCardPoint;
        this.overlay = false;
        this.sendSuccessDialog = true;

        this.isusepoint = false;
        this.isearncoin = false;

        //入力フォームクリア
        this.earnCoinMember = null; //選択した送信先情報のクリア
        this.earnCoinMembername = "";
        this.sendCandidate = []; //検索結果エリアのクリア
        this.thanks_message = "";
        this.selectedPhilosophy = "";
      } catch (err) {
        this.cardSendConfirmationDialog = false;
        this.overlay = false; //オーバレイをOFF
        this.sendfailedDialog = true; //送信失敗ダイアログをON
        this.isusepoint = false;
        this.isearncoin = false;
        //入力内容初期化
        this.earnCoinMember = null; //選択した送信先情報のクリア
        this.earnCoinMembername = "";
        this.sendCandidate = []; //検索結果エリアのクリア
        this.thanks_message = "";
        this.selectedPhilosophy = "";

        // 送信エラーとなった場合でもエラーメッセージ等は出さない。
        await Methods.senderrmail("user", {
          error: err,
        });
      }
    },
  },
};
</script>

<style></style>
