<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-tabs background-color="light-green" dark grow>
          <v-tab>社員データインポート</v-tab>
        </v-tabs>
        <v-card height="350">
          <v-container>
            <v-card-text>
              取り込みたいファイルを選択して<br />
              ”インポート”ボタンを押下することで、<br />
              選択したcsvファイルから社員データをデータベースにインポートします。 <br />
            </v-card-text>
          </v-container>
          <v-form ref="import_validate">
            <v-container class = "py-6">
              <v-file-input
                ref="fileupload"
                label="データインポートするCSVファイルを選択"
                @change="getFileContent"
                :rules="[rules.file_required]"
                accept=".csv,text/csv,application/vnd.ms-excel"
                :clearable="true"
                class="mt-n10"
                prepend-icon="mdi-paperclip"
                outlined
              >
                <template v-slot:selection="{ text }">
                  <v-chip label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <v-card-text class="pt-0 pl-1 text-left blue--text">
                ※データが250件以上の場合、インポートに失敗する可能性があります。
              </v-card-text>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-btn @click="conformDialog = true" absolute bottom right>{{
              loadLabel
            }}</v-btn>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="conformDialog" max-width="600">
          <v-card height="auto" max-width="auto">
            <v-card-text>
              <div class="body-1 mb-1 text--primary">
                <br />選択したファイルのデータを社員情報DBにインポートします。
                <br />よろしいですか？
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="load">
                インポートする
              </v-btn>
              <v-btn color="blue darken-1" text @click="conformDialog = false">
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay :value="overlay">
          <VueLoading
            type="spiningDubbles"
            color="white"
            :size="{ width: '150px', height: '150px' }"
          ></VueLoading>
          <span class="font-weight-bold" style="font-size: 2em">
            now import...
          </span>
        </v-overlay>
        <v-dialog v-model="csvDisabledImportErr" max-width="478px">
          <v-card>
            <v-row justify="center" class="pa-6">
              <v-img
                max-height="100"
                max-width="100"
                src="@/assets/send_failed.png"
              ></v-img>
            </v-row>
            <v-col align="center">
              <v-card-text class="pa-2">
                <v-card-title class="v-card__title.headline">
                  一部のデータが登録エラーになりました
                </v-card-title>
                <div
                  class="body-1 mb-1 text--primary"
                  v-html="csvDisabledImportMessage"
                ></div>
              </v-card-text>
            </v-col>
            <v-btn @click="downloadCsvErrFile" color="light-green" dark
              >ダウンロード
              <v-icon right> mdi-download-circle-outline </v-icon>
            </v-btn>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="csvDisabledImportErr = false"
                >閉じる</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Methods from "@/api/methods";
import { VueLoading } from "vue-loading-template";
import swal from "sweetalert";
import iconv from "iconv-lite";
import encoding from "encoding-japanese";

let csvData = {};
let isFormatError = false;

export default {
  props: {},
  components: {
    VueLoading,
  },
  created () {
    // キャッシュ削除
    if (process.env.NODE_ENV === "production") {
      window.navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      caches.keys().then((keys) => {
        keys.forEach((cacheName) => {
          if (cacheName) {
            caches.delete(cacheName);
          }
        });
      });
    }
    this.loadLabel = "インポート";
  },
  data () {
    return {
      // loading: false,
      loadLabel: "",
      // csvData: {},
      conformDialog: false,
      overlay: false,
      rules: {
        file_required: (value) =>
          !!value || "インポートするファイルを選択して下さい",
      },
      validate: false,
      import_count: 0,
      csvDisabledImportMessage: "",
      disabledImportUser: "",
      csvDisabledImportErr: false,
      errInfo: [],
      errCsv: [],
    };
  },
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("admin")).data;
    if (!user) {
      console.log("未ログイン。ログイン画面へ遷移");
      this.$router.push("/adminsignin");
      return;
    }
    if (user.auth === "not_auth_user") {
      console.log("ユーザ権限無し");
      this.$router.push("/adminNotAuth");
    }
  },
  methods: {
    async getFileContent (importfile) {
      csvData = {};
      isFormatError = false;

      if (importfile !== null) {
        //見やすくするために格納
        let validateCheck = this.$refs.import_validate.validate();
        //入力チェック（年月指定していない）ひっかかると、CSV選択解除及びダイアログ出現
        if (!validateCheck) {
          //CSVファイルの選択解除
          this.$refs.fileupload.clearableCallback();

          swal({
            title: "notice!!",
            text: "データが正しく取得出来ないため、\nファイル選択操作をやり直して下さい。",
            icon: "warning",
            button: "閉じる",
          });
          return;
        }

        csvData = await this.parseCsv(importfile);

        if (
          csvData.length === 0 ||
          !(
            Object.prototype.hasOwnProperty.call(csvData[0], "番号") &&
            Object.prototype.hasOwnProperty.call(csvData[0], "氏名") &&
            Object.prototype.hasOwnProperty.call(csvData[0], "カナ") &&
            Object.prototype.hasOwnProperty.call(
              csvData[0],
              "メールアドレス"
            ) &&
            Object.prototype.hasOwnProperty.call(csvData[0], "会社") &&
            Object.prototype.hasOwnProperty.call(csvData[0], "組織") &&
            Object.prototype.hasOwnProperty.call(csvData[0], "性別") &&
            Object.prototype.hasOwnProperty.call(csvData[0], "表示") &&
            Object.prototype.hasOwnProperty.call(csvData[0], "無効フラグ")
          )
        ) {
          isFormatError = true;
          swal({
            title: "notice!!",
            text: "ファイルの項目を正しく取得できません。\nファイルの内容を確認して下さい。\n例：項目が不足している・文字化けしている",
            icon: "warning",
            button: "閉じる",
          });
        }
      }
    },

    parseCsv (file) {
      return new Promise((resolve) => {
        // 文字コード判定のためファイルを読み込み
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onerror = function (e) {
          console.log(e);
          resolve(null);
        };
        const papa = this.$papa;
        reader.onload = async function (e) {
          // SJISの場合は変換する
          const buff = Buffer.from(e.target.result);
          let encodedFile = buff.toString();
          if (encoding.detect(buff) === "SJIS") {
            encodedFile = iconv.decode(buff, "Shift_JIS");
          }
          // CSVをパース
          const results = papa.parse(encodedFile, {
            header: true,
            comments: "",
            skipEmptyLines: "greedy",
          });
          resolve(results.data);
        };
      });
    },

    async load () {
      const user = (await Methods.getAuthUserInfo("admin")).data;
      if (!user) {
        console.log("ログイン画面へ遷移");
        this.$router.push("/adminsignin");
        return;
      }
      if (user.auth === "not_auth_user") {
        console.log("ユーザ権限無し");
        this.$router.push("/adminNotAuth");
        return;
      }

      this.conformDialog = false;
      //インポートボタンが押されたタイミングで以下が解消されていない
      //CSVがパースされてない（CSVが存在しない） or 項目エラー
      if (
        !this.$refs.import_validate.validate() ||
        csvData.length === undefined ||
        isFormatError
      ) {
        swal({
          title: "error",
          text: "インポートするデータを正しく取得出来ていないため、\n正しいファイルを再度選択してください。",
          icon: "error",
          button: "閉じる",
        });
        return;
      }

      this.loadLabel = "データインポート中・・・";
      this.overlay = true;

      const resOfMongoImport = await Methods.userImport("admin", csvData);
      //見やすくするためにdataまで格納
      const response = resOfMongoImport.data;
      //各返却項目を格納
      const updateCount = response.updateCount;
      const newCount = response.newCount;
      const csvAllCount = response.csvAllCount;
      const errCount = response.errCount;

      //コード確認（0:成功（入力エラー含む）、-1:システムエラー）
      const code = response.code;
      if (code === 0) {
        //CSVの先頭に空白が入るため、ここで除去
        this.errCsv = response.errCsv.trim();

        console.log("データインポート処理が正常終了");
        this.overlay = false;
        if (errCount === 0) {
          swal({
            title: "データインポートが完了しました！",
            text:
              "インポートしたデータは" +
              csvAllCount +
              "件です。新規：" +
              newCount +
              "件 更新：" +
              updateCount +
              "件",
            icon: "success",
            button: "閉じる",
          });
        } else {
          this.csvDisabledImportErr = true;
          this.csvDisabledImportMessage =
            "<br>インポート用のデータは" +
            csvAllCount +
            "件です。<br>新規：" +
            newCount +
            "件 更新：" +
            updateCount +
            "件 登録エラー：" +
            errCount +
            "件<br>登録エラーのデータは、登録されていません。<br>詳細はダウンロードして確認してください。";
        }
      } else {
        this.overlay = false;
        swal({
          title: "システムエラー",
          text: "データインポートが失敗しました。\nお手数おかけしますが、開発者に連絡をお願いします。",
          icon: "error",
          button: "閉じる",
        });
      }
      this.loadLabel = "インポート";
    },

    downloadCsvErrFile () {
      // CSV入力エラー詳細ファイルをダウンロードさせる
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, this.errCsv], { type: "text/csv" });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.setAttribute("download", "ImportErrorInfo.csv");
      a.click();
      a.remove();
    },
  },
};
</script>
<style>
.v-card__title.headline {
  font-size: 1.3rem !important;
  text-align: center;
}
</style>
