<template>
  <section class="hero is-medium has-text-centered">
    <div class="hero-body hero-body-hp-main">
      <div class="container">
        <div class="columns">
          <div class="column">
            <video
              width="300"
              height="300"
              autoplay
              muted
              playsinline
            >
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import jsQR from "jsqr";

export default {
  name: "QrCamera",
  data () {
    return {
      stream: null,
    };
  },
  mounted () {
    this.startReadImage();
  },
  beforeDestroy () {
    this.cameraStop();
  },
  methods: {
    startReadImage () {
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          width: 300,
          height: 300,
          frameRate: { ideal: 5, max: 15 },
          facingMode: "environment",
        }
      }).then((mediaStream) => {
        const video = document.querySelector("video");
        this.stream = mediaStream;
        video.srcObject = this.stream;

        const canv = document.createElement("canvas");
        canv.height = 300;
        canv.width = 300;

        const context = canv.getContext("2d", { willReadFrequently: true });

        const intervalId = setInterval(() => {
          context.drawImage(video, 0, 0, 300, 300);
          const imageData = context.getImageData(0, 0, 300, 300);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            this.cameraStop();
            clearInterval(intervalId);
            this.$emit("qrDetected", code.data);
          }
        }, 500);
      });
    },
    cameraStop () {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }
      document.querySelector("video").srcObject = null;
    },
  },
};
</script>
