<template>
  <div>
    <v-app>
      <v-main>
        <v-card>
          <v-card-title>ログアウトしました</v-card-title>
        </v-card>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Methods from "@/api/methods";

export default {
  async mounted () {
    await Methods.logout("user");
    this.$router.push("/");
  },
};
</script>

<style></style>
