<template>
  <div id="app">
    <!--↓各コンポーネントが表示される場所-->
    <component v-bind:is="layout()"></component>
    <!--↑ログイン画面のみヘッダー、フッター出ないよう判定-->
  </div>
</template>

<script>
import DefaultLayout from "./components/layout/DefaultLayout.vue";
import NoneLayout from "./components/layout/NoneLayout.vue";
import AdminLayout from "./components/layout/AdminLayout.vue";
import { VueLoading } from "vue-loading-template";

export default {
  name: "App",

  components: {
    AdminLayout,
    DefaultLayout,
    NoneLayout,
    VueLoading,
  },

  data: () => ({
    //
  }),
  methods: {
    layout () {
      // router.jsのroutesにmeta.layoutの存在確認し、セットするレイアウトを判断する
      let layout = this.$route.meta.layout
        ? this.$route.meta.layout + "-layout"
        : "default-layout";
      return layout;
    },
  },
};
</script>

<style>
body {
  margin: 0;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#header {
  height: 40px;
  background: white;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

#header a {
  text-decoration: none;
  color: #2c3e50;
  margin: 0 10px;
  padding: 3px 10px;
  background: #5ccebf;
}
.v-dialog > .v-card {
  overflow: hidden;
}
</style>
