import axios from "axios";

export default (mode) => {
  return axios.create({
    baseURL:
      location.protocol +
      "//" +
      document.domain +
      process.env.VUE_APP_API_URL_BASE +
      mode,
    withCredentials: true,
    timeout: 15000,
  });
};
