<template>
  <v-combobox
    v-model="_model"
    :label="label"
    :items="searchResults"
    no-filter
    :search-input.sync="_searchInput"
    :allow-overflow="false"
    hide-no-data
    hide-selected
    return-object
    multiple
    hide-details
    :disabled="readonly"
    :item-text="itemText"
    :item-value="itemIdentifier"
    outlined
    prepend-icon="mdi-account-search"
  >
    <!-- 選択した要素はチップで表示 -->
    <template v-if="_model.length > 0" v-slot:selection="data">
      <template>
        <v-chip
          close
          close-icon="mdi-close-circle"
          class="chip--select-multi"
          color="blue"
          text-color="white"
          @click:close="remove(data.item)"
        >
          {{ data.item[itemText] }}
          <v-icon light>mdi-account</v-icon>
        </v-chip>
      </template>
    </template>

    <!-- 検索結果の表示部 -->
    <template v-slot:item="data">
      <template>
        <v-list>
          <v-list-item-content>
            <v-list-item-title
            >{{ data.item[firstItemText] + separateText +data.item[secondItemText] }}</v-list-item-title>
            <v-list-item-subtitle
            >{{ data.item[itemSubText] }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list>
      </template>
    </template>
  </v-combobox>
</template>

<script>
import { debounce, isEmpty, isEqual } from "lodash";

export default {
  props: {
    model: {
      type: [Object, Array, String],
      default: null,
    },
    searchInput: {
      type: String,
      default: "",
    },
    searchFunc: {
      type: Function,
      required: true,
    },
    searchResults: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "",
    },
    firstItemText: {
      type: String,
      default: "",
    },
    secondItemText: {
      type: String,
      default: "",
    },
    separateText: {
      type: String,
      default: "",
    },
    itemSubText: {
      type: String,
      default: "",
    },
    itemIdentifier: {
      type: String,
      default: "",
    },
    selectMax: {
      type: Number,
      default: 5,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    const searchWithInterval = (value) => {
      return this.searchFunc(value).then(() => {
        return Promise.resolve();
      });
    };
    return {
      searchInputTemp: null,
      focused: false,
      search: debounce((value) => {
        //APIの実行を1.0秒に1回に制限
        if (!isEmpty(value)) {
          let searchPromise = searchWithInterval(value);
          Promise.all([searchPromise]);
        } else {
          this.$emit("update:search-results", []);
        }
      }, 1000),
    };
  },
  computed: {
    _model: {
      get () {
        return this.model;
      },
      set (newVal) {
        return this.$emit("update:model", newVal);
      },
    },
    _searchInput: {
      get () {
        return this.searchInput;
      },
      set (newVal) {
        if (newVal != null) {
          this.searchInputTemp = newVal;
        }
        this.$emit("update:search-input", newVal);
      },
    },
  },
  watch: {
    //インクリメンタルサーチ
    searchInputTemp (val) {
      console.log("searchInputTemp", val);
      this.search(val);
    },
    // APIで取得した値のみ入力可能
    model (val, prev) {
      // 何もしないと、入力した条件も文字列でmodelの配列に設定されてしまう。
      // そのため、model配列からObject型のものだけ抽出(String型は排除する)してmodelに設定する。
      // 本処理はmodelの値が変更される度に呼ばれるため、今回値と前回値が異なる場合のみ処理する(そうしないと無限ループとなる)
      if (!isEqual(val, prev)) {
        let objcectModel = val.filter((item) => typeof item === "object");
        console.log("送信先情報？" + JSON.stringify(objcectModel));
        if (objcectModel.length > this.selectMax) {
          // 選択数が最大値を越えた場合は越えた範囲を削除
          objcectModel = objcectModel.slice(0, this.selectMax);
        }
        this.$emit("update:model", objcectModel);
        // 社員が選択されたら検索条件、検索結果もクリアする
        this.$emit("update:search-input", "");
        this.searchInputTemp = "";
        this.$emit("update:search-results", []);
      }
    },
  },
  methods: {
    //チップを削除するメソッド。チップの×ボタンを押下されると発火する。
    remove (removeObj) {
      // console.log("remove", this.searchInputTemp, removeObj);
      // 削除がクリックされたオブジェクト以外を抽出して親コンポーネントに返す
      const removedModel = this.model.filter(
        (item) => item.accountnumber !== removeObj.accountnumber
      );
      this.$emit("update:model", removedModel);
      this.searchInputTemp = null;
    },
  },
};
</script>
<style>
.v-list-item .v-list-item__title{
  white-space:pre-wrap;
}

</style>
