<template>
  <v-card height="auto" max-width="auto" outlined>
    <v-row justify="center">
      <v-card-title class="headline">
        <div v-html="title"></div>
      </v-card-title>
      <v-divider class="mx-3"></v-divider>
      <v-card-text style="white-space: pre-line">
        <div v-html="text"></div>
      </v-card-text>
      <v-row justify="center">
        <v-col></v-col>
        <v-col>
          <v-img
            height="150"
            width="150"
            src="@/assets/send_failed.png"
          ></v-img
        ></v-col>
        <v-col></v-col>
      </v-row>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="$emit('dialogClosed')"
      >閉じる</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "GenericFailureCard",
  props: {
    title: String,
    text: String,
  },
};
</script>
