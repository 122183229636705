<template>
  <button class="share-button" :class="[type, size]" @click="click">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
  },
  methods: {
    click () {
      //buttunタグのclickイベント=>clickメソッド=>呼び出しvue側のclickイベント=>そのvueのクリックメソッドでイベント発生していく
      this.$emit("buttunclick");
    },
  },
};
</script>

<style>
.share-button {
  background: #f6f6f6;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  color: #666;
  cursor: pointer;
  font-size: 14px;
  margin: 2px 5px;
  outline: none;
  padding: 10px 20px;
  transition: opacity 0.4s;
}
.share-button:hover {
  opacity: 0.8;
}
.share-button:active {
  transform: translateY(3px);
  box-shadow: none;
}
.share-button.primary {
  background: #0096E6;
  color: #ffffff;
}
.share-button.admin {
  background: #8BC34A;
  color: #ffffff;
}
.share-button.success {
  background: #2ecc71;
  color: #ffffff;
}
.share-button.info {
  background: #3498db;
  color: #ffffff;
}
.share-button.warning {
  background: #f1c40f;
  color: #ffffff;
}
.share-button.danger {
  background: #e74c3c;
  color: #ffffff;
}
.share-button.small {
  font-size: 10px;
  padding: 5px 10px;
}
.share-button.large {
  font-size: 18px;
  padding: 15px 30px;
}
</style>
