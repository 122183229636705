<template>
  <v-app>
    <v-main>
      <v-card width="800px" class="mx-auto mt-16">
        <img
          src="@/assets/logo_main02.png"
          style="width: 100%; height: 56.25%"
        />
        <v-card-actions class="justify-center">
          <share-button @buttunclick="azureAdLogin" type="primary"
            >Sign in with AzureAD</share-button
          >
        </v-card-actions>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import shareButton from "@/components/ShareButton.vue";
import Methods from "@/api/methods";

export default {
  components: {
    shareButton,
  },
  data: () => ({}),
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("user")).data;
    if (user.auth === "not_auth_user") {
      console.log("ユーザ権限無し");
      this.$router.push("/notAuth");
      return;
    }
    if (user) {
      // ログイン後のページに遷移する
      this.$router.push("/mypage");
    }
  },
  methods: {
    async azureAdLogin () {
      // ログイン
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
