<template>
  <div class="top">
    <v-app id="inspire">
      <v-main>
        <v-container fluid>
          <v-card>
            <v-tabs v-model="tab" background-color="light-green" dark grow>
              <v-tabs-slider color="orange"></v-tabs-slider>
                <v-tab v-for="item in tabitems" :key="item.tab">{{
                  item.tab
                }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tabitems" :key="item.tab">
                <v-card>
                  <div v-if="item.tab === '月間抽出'">
                    <v-container>
                      <v-card-text>
                        年月を指定することで任意の1か月分の履歴をエクスポートすることができます。<br />
                        年月は必ず指定してください。年のみ月のみの指定はできません。 <br />
                        例）2023年2月度を指定した場合、2023年2月10日00時00分～3月9日23時59分<br />
                      </v-card-text>
                    <v-form ref="valid1">
                      <v-container grid-list-md>
                        <v-layout row wrap>
                          <v-row class = "justify-center pa-6">
                            <v-flex xs12 lg4>
                              <v-select
                                v-model="year"
                                clearable
                                label="年"
                                :items="yearsList"
                                variant="underlined"
                                :rules="[required]"
                              ></v-select>
                            </v-flex>
                            <v-flex xs12 lg4>
                              <v-select
                                v-model="month"
                                clearable
                                label="月度"
                                :items="monthList"
                                variant="underlined"
                                :rules="[required]"
                              ></v-select>
                            </v-flex>
                            <v-flex xs12 lg2>
                              <v-btn @click="exportUsageInquiryCsv()">{{
                                  loadLabel
                              }}</v-btn>
                            </v-flex>
                          </v-row>
                        </v-layout>
                      </v-container>
                    </v-form>
                    </v-container>
                  </div>
                <v-overlay :value="overlay">
                  <VueLoading
                    type="spiningDubbles"
                    color="white"
                    :size="{ width: '150px', height: '150px' }"
                  ></VueLoading>
                  <span class="font-weight-bold" style="font-size: 2em">
                    now exporting...
                  </span>
                </v-overlay>
                <div v-if="item.tab === '個人抽出'">
                  <v-container>
                    <v-card-text>
                      対象者と抽出区分を指定することで全履歴をエクスポートできます。<br />
                    </v-card-text>
                  </v-container>
                  <v-form ref="valid2">
                    <v-container>
                      <AppCombobox
                        label="CSV出力対象者を選択(対象者の情報を入力すると自動検索します)"
                        :model.sync="csvOutputTarget"
                        :search-input.sync="InputKeyword"
                        :search-func="SearchMember"
                        :search-results.sync="SearchResult"
                        item-text="membername"
                        first-item-text="department"
                        second-item-text="membername"
                        separate-text=" / "
                        item-sub-text="mail"
                        item-identifier="accountnumber"
                        single
                        :rules="[required]"
                        ref="searchComboBox"
                        ></AppCombobox>
                        <v-radio-group v-model="selectedRadio" row="true">
                          <v-radio
                            v-for="(item, index) in historyTypeArray"
                            :key="index"
                            :label="item.name"
                            :value="item.value"
                            :rules="[required]"
                          ></v-radio>
                        </v-radio-group>
                      <v-layout row wrap>
                        <v-row class = "justify-center pa-6">
                          <v-flex xs12 lg2>
                              <v-btn
                                class="mt-4 mr-8 ml-auto pr-3"
                                :disabled="!csvOutputTarget"
                                @click="exportUserActivityCsv()"
                              >エクスポート</v-btn>
                          </v-flex>
                        </v-row>
                      </v-layout>
                    </v-container>
                  </v-form>
                </div>
                <v-divider class="mx-3"></v-divider>
                  <v-overlay :value="overlay">
                    <VueLoading
                      type="spiningDubbles"
                      color="white"
                      :size="{ width: '150px', height: '150px' }"
                    ></VueLoading>
                    <span class="font-weight-bold" style="font-size: 2em">
                      now exporting...
                    </span>
                  </v-overlay>

                  <v-dialog v-model="exportcsvfailedDialog" max-width="400">
                    <v-card>
                      <v-card-title class="headline"> 出力失敗・・・ </v-card-title>
                      <v-divider class="mx-3"></v-divider>
                      <v-card-text>
                        <div class="body-1 mb-1 text--primary">
                          <br />一括出力中にエラーが<br />発生しました。
                          <br />お手数ですが、再度出力を <br />お試しください。
                        </div>
                      </v-card-text>
                      <v-row justify="center">
                        <v-col></v-col>
                        <v-col>
                          <v-img
                            height="150"
                            width="150"
                            src="@/assets/send_failed.png"
                          ></v-img>
                        </v-col>
                        <v-col></v-col>
                      </v-row>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="exportcsvfailedDialog = false"
                        >閉じる</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import Methods from "@/api/methods";
import AppCombobox from "@/components/AppCombobox";
import { VueLoading } from "vue-loading-template";
import swal from "sweetalert";

export default {
  props: {
    source: String,
  },
  components: {
    VueLoading,
    AppCombobox,
  },
  created () {
    // キャッシュ削除
    if (process.env.NODE_ENV === "production") {
      window.navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      caches.keys().then((keys) => {
        keys.forEach((cacheName) => {
          if (cacheName) {
            caches.delete(cacheName);
          }
        });
      });
    }
    this.loadLabel = "エクスポート";
    //描画前にリスト作成
    this.createYearList();
  },
  data () {
    return {
      //drawer: null,
      tab: null,
      tabitems: [
        { tab: "月間抽出", content: "" },
        { tab: "個人抽出", content: "" },
      ],
      loadLabel: "",
      overlay: false,
      year: "",
      month: "",
      yearsList: [],
      monthList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      required: (value) => !!value || "必ず入力してください",
      InputKeyword: "",
      //対象社員検索結果のレスポンス
      SearchResult: this.$router.currentRoute.params.member
        ? [this.$router.currentRoute.params.member]
        : [],
      csvOutputTarget: this.$router.currentRoute.params.member,
      //ラジオボタン表示
      historyTypeArray: [{
        name: "もらった",
        value: "R",
      }, {
        name: "おくった",
        value: "S"
      }],
      //ラジオボタン初期値設定、受け取り
      selectedRadio: "R",
      //エクスポート失敗ダイアログ
      exportcsvfailedDialog: false,
    };
  },
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("admin")).data;
    if (!user) {
      console.log("未ログイン。ログイン画面へ遷移");
      this.$router.push("/adminsignin");
      return;
    }
    if (user.auth === "not_auth_user") {
      console.log("ユーザ権限無し");
      this.$router.push("/adminNotAuth");
    }
  },
  methods: {
    createYearList () {
      let date = new Date();
      let nowyear = date.getFullYear();
      for (let i = 2022; i <= nowyear; i++) {
        this.yearsList.push(i);
      }
    },
    async exportUsageInquiryCsv () {
      if (this.$refs.valid1[0].validate()) {
        this.overlay = true;
        let dateParams = {};
        let toMonth = "";
        let toYear = "";
        let fromMonth = "";
        let fromYear = this.year;
        if (this.month === 12) {
          toMonth = 1;
          toYear = this.year + 1;
        } else {
          toMonth = this.month + 1;
          toYear = this.year;
        }
        //1桁の数字を0埋めする
        toMonth = ("0" + toMonth).slice(-2);
        fromMonth = ("0" + this.month).slice(-2);
        //queryパラメータの形に成型
        dateParams.from = fromYear + fromMonth + "10";
        dateParams.to = toYear + toMonth + "09";
        console.log("From/To：" + dateParams.from + "/" + dateParams.to);
        let res = "";
        try {
          res = await Methods.exportSendPointHistoryCsv("admin", dateParams);
        } catch (err) {
          this.overlay = false;
          swal({
            //(エラーの文言について)res.data.code === -1のロジックの文言に合わせないで、個人抽出の画面に近づけた
            title: "システムエラー",
            text: "システムエラーが発生しました。\nお手数をおかけしますが、開発者に連絡をお願いします。",
            icon: "info",
            button: "閉じる",
          });
          return;
        }
        if (res.data.code === -1) {
          this.overlay = false;
          swal({
            title: "システムエラー",
            text: "エクスポートが失敗しました。\nお手数をおかけしますが、開発者に連絡をお願いします。",
            icon: "error",
            button: "閉じる",
          });
        } else {
          this.overlay = false;
          //成功時、ダウンロードをする
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const blob = new Blob([bom, res.data], { type: "text/csv" });
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.setAttribute("download", "UsageInquiry.csv");
          a.click();
          a.remove();
        }
      }
    },
    async SearchMember (serchInput) {
      //検索処理はメール、社番、氏名を結合した値に対して、前方一致で行われる。
      let response = await Methods.membersearch("admin", serchInput);
      if (response.data === "searchkeyword_null") {
        response.data = [];
        this.SearchResult = [];
      }
      this.SearchResult = response.data;
      console.log(
        "SearchResultの中身は：",
        this.SearchResult,
        "csvOutputTargetの中身は：",
        this.csvOutputTarget
      );
    },
    //対象ユーザ履歴一括出力
    async exportUserActivityCsv () {
      //対象ユーザ未入力かどうかのnullチェック
      if (!this.csvOutputTarget) {
        this.exportcsvfailedDialog = true; //エクスポート失敗ダイアログをオン
      } else {
        this.overlay = true; //処理中ローディング画面をオン
        this.exportcsvfailedDialog = false;
        let csvHistoryName = "";
        let res = {};
        const requestParam = {
          accountnumber: this.SearchResult[0].accountnumber,
          historyType: this.selectedRadio,
        };
        console.log("エラー調査：社員番号" + requestParam.accountnumber);
        try {
          //メソッド呼び出し
          res = await Methods.exportUserWholeHistoryCsv("admin", requestParam);
        } catch (err) {
          //予期せぬエラーが起きた時
          console.log(JSON.stringify(err));
          this.overlay = false; //処理中ローディング画面をオフ
          this.exportcsvfailedDialog = true; //エクスポート失敗ダイアログをオン
          return;
        }
        //CSVファイル名生成
        if (this.selectedRadio === "R") {
          csvHistoryName = "受信履歴";
        } else {
          csvHistoryName = "送信履歴";
        }
        console.log("選択されているのは" + csvHistoryName + "です");
        if (res.data.code === -1) {
          //エラーコードが返って来た時
          this.overlay = false; //処理中ローディング画面をオフ
          this.exportcsvfailedDialog = true; //エクスポート失敗ダイアログをオン
        } else {
          //成功時、ダウンロードをする
          let date = new Date();
          let csvfilename = `【${csvHistoryName}】${this.csvOutputTarget.membername}さん_${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const blob = new Blob([bom, res.data], { type: "text/csv" });
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.setAttribute("download", csvfilename);
          a.click();
          a.remove();
          this.overlay = false; //処理中ローディング画面をオフ
          console.log(this.csvOutputTarget.membername + "の個人抽出エクスポート成功");
        }
      }
    }
  },
};
</script>
<style>
</style>
