<template>
  <div class="top">
    <v-app id="inspire">
      <v-main>
        <v-container fluid>
          <v-card>
            <v-tabs v-model="tab" background-color="light-green" dark grow>
              <v-tabs-slider color="orange"></v-tabs-slider>
              <v-tab v-for="item in tabitems" :key="item.tab">{{
                item.tab
              }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tabitems" :key="item.tab">
                <v-card>
                  <div v-if="item.tab === '一括利用'">
                    <v-container>
                      <v-card-title>コイン利用情報CSVファイル</v-card-title>
                      <v-file-input
                        label="コイン利用情報CSVファイルを選択"
                        v-model="file"
                        accept=".csv"
                        :clearable="true"
                        class="mt-1"
                        prepend-icon="mdi-paperclip"
                        outlined
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip label color="primary">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>

                      <div>
                        <v-dialog
                          v-model="uploadCsvDialog"
                          persistent
                          max-width="400"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="light-green"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="openCsvUploadDialog"
                            >
                              確定
                            </v-btn>
                          </template>
                          <v-card
                            height="auto"
                            max-width="auto"
                            v-if="isFileCheck"
                            shaped
                          >
                            <v-row justify="center">
                              <v-col></v-col>
                              <v-col>
                                <v-img
                                  height="150"
                                  width="150"
                                  v-bind:src="require('@/assets/putcoin.png')"
                                ></v-img>
                              </v-col>
                              <v-col></v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-card-title class="headline"
                                >コイン一括利用処理を<br />実行しますか？</v-card-title
                              >
                            </v-row>
                            <v-card-text> コインを一括利用します </v-card-text>
                            <v-card-actions>
                              <v-row>
                                <v-col>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="coinSendFromCsv"
                                    >はい</v-btn
                                  >
                                </v-col>
                                <v-col>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="uploadCsvDialog = false"
                                    >いいえ</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-card-actions>
                          </v-card>

                          <v-card v-else height="auto" max-width="auto" shaped>
                            <v-row justify="center">
                              <v-col></v-col>
                              <v-col>
                                <v-img
                                  height="150"
                                  width="150"
                                  src="@/assets/send_failed.png"
                                ></v-img
                              ></v-col>
                              <v-col></v-col>
                            </v-row>
                            <v-card-text>
                              <div
                                class="body-1 mb-1 text--primary"
                                v-html="csvValidateErrorMessage"
                              >
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="uploadCsvDialog = false"
                                >閉じる</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </v-container>
                  </div>

                  <div v-if="item.tab === '個別利用'">
                    <v-container>
                      <div id="thankscard" class="my-n3">
                        <v-container>
                          <v-card-title>コイン利用の送信先</v-card-title>
                          <AppCombobox
                            label="コイン利用先を選択(贈り先の情報を入力すると自動検索します)"
                            :model.sync="earnCoinMember"
                            :search-input.sync="usecoinsDestination"
                            :search-func="searchusecoinsDestination"
                            :search-results.sync="sendCandidate"
                            item-text="membername"
                            first-item-text="department"
                            second-item-text="membername"
                            separate-text=" / "
                            item-sub-text="mail"
                            item-identifier="accountnumber"
                            single
                            :rules="[required]"
                          ></AppCombobox>
                        </v-container>
                      </div>
                      <v-divider class="mx-3"></v-divider>

                      <div id="coins" class="my-n3">
                        <v-form v-model="valid" ref="form">
                          <v-container>
                            <v-card-title>コイン数</v-card-title>
                            <!-- item-textは画面にレンダリングされる値、item-valueはDOM取得される値 -->
                            <v-text-field
                              v-model.number="useCoins"
                              label="コイン数を入力してください。差し引く場合は頭に-を入力ください"
                              single-line
                              prepend-icon="mdi-currency-usd-circle"
                              :rules="coinRule"
                              outlined
                            ></v-text-field>
                          </v-container>
                        </v-form>
                      </div>
                      <v-divider></v-divider>

                      <div id="comment" class="my-n3">
                        <v-container>
                          <v-card-title>メッセージ</v-card-title>
                          <v-text-field
                            v-model="thanks_message"
                            label="コメントを入力して下さい"
                            single-line
                            prepend-icon="mdi-pen"
                            outlined
                          ></v-text-field>
                        </v-container>
                      </div>

                      <div id="cardsendbutton">
                        <v-dialog
                          v-model="cardSendConfirmationDialog"
                          persistent
                          max-width="400"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="light-green"
                              class="white--text"
                              v-bind="attrs"
                              v-on="on"
                              :disabled="!valid"
                              @click="openThanksCardDialog"
                            >
                              確定
                            </v-btn>
                            <v-col></v-col>
                          </template>
                          <v-card
                            height="auto"
                            max-width="auto"
                            v-if="isdest_check"
                            shaped
                          >
                            <v-row justify="center">
                              <v-col></v-col>
                              <v-col>
                                <v-img
                                  height="150"
                                  width="150"
                                  v-bind:src="require('@/assets/putcoin.png')"
                                ></v-img>
                              </v-col>
                              <v-col></v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-card-title class="headline"
                                >コインを割り当てますか？</v-card-title
                              >
                            </v-row>
                            <v-card-text>
                              {{ earnCoinMembername }}さんにコインを割り当てます
                            </v-card-text>
                            <v-card-actions>
                              <v-row>
                                <v-col>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="coinSend"
                                    >はい</v-btn
                                  >
                                </v-col>
                                <v-col>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="cardSendConfirmationDialog = false"
                                    >いいえ</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-card-actions>
                          </v-card>

                          <v-card v-else height="auto" max-width="auto" shaped>
                            <v-row justify="center">
                              <v-col></v-col>
                              <v-col>
                                <v-img
                                  height="150"
                                  width="150"
                                  src="@/assets/send_failed.png"
                                ></v-img
                              ></v-col>
                              <v-col></v-col>
                            </v-row>
                            <v-card-text>
                              <div class="body-1 mb-1 text--primary">
                                <br />送信先が未選択のため、
                                <br />送信先を選び直して下さい。
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="cardSendConfirmationDialog = false"
                                >閉じる</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </v-container>
                  </div>

                  <v-dialog v-model="sendmongofailedDialog" max-width="400">
                    <v-card>
                      <v-card-title class="headline">
                        送信失敗・・・
                      </v-card-title>
                      <v-divider class="mx-3"></v-divider>
                      <v-card-text>
                        <div class="body-1 mb-1 text--primary">
                          <br />送信中にエラーが発生しました。
                          <br />お手数ですが、再度送信を <br />お試しください。
                        </div>
                      </v-card-text>
                      <v-row justify="center">
                        <v-col></v-col>
                        <v-col>
                          <v-img
                            height="150"
                            width="150"
                            src="@/assets/send_failed.png"
                          ></v-img>
                        </v-col>
                        <v-col></v-col>
                      </v-row>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="sendmongofailedDialog = false"
                          >閉じる</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="sendfailedDialog" max-width="400">
                    <v-card>
                      <v-card-title class="headline">
                        送信失敗・・・
                      </v-card-title>
                      <v-divider class="mx-3"></v-divider>
                      <v-card-text>
                        <div class="body-1 mb-1 text--primary">
                          <br />送信中にエラーが発生しました。
                          <br />システム担当へ対応を依頼<br />して下さい。
                        </div>
                      </v-card-text>
                      <v-row justify="center">
                        <v-col></v-col>
                        <v-col>
                          <v-img
                            height="150"
                            width="150"
                            src="@/assets/send_failed.png"
                          ></v-img>
                        </v-col>
                        <v-col></v-col>
                      </v-row>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="sendfailedDialog = false"
                          >閉じる</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="sendfailedDialog2" max-width="400">
                    <v-card>
                      <v-card-title class="headline">
                        送信失敗・・・
                      </v-card-title>
                      <v-divider class="mx-3"></v-divider>
                      <v-card-text>
                        <div class="body-1 mb-1 text--primary">
                          <br />送信中にエラーが発生しました。
                          <br />再送信せずに、
                          <br />エラー内容を確認してください。
                        </div>
                      </v-card-text>
                      <v-row justify="center">
                        <v-col></v-col>
                        <v-col>
                          <v-img
                            height="150"
                            width="150"
                            src="@/assets/send_failed.png"
                          ></v-img>
                        </v-col>
                        <v-col></v-col>
                      </v-row>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="sendfailedDialog2 = false"
                          >閉じる</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="csvInputErr" max-width="400">
                    <v-card>
                      <v-card-title class="headline">
                        CSVファイルエラー・・・
                      </v-card-title>
                      <v-divider class="mx-3"></v-divider>
                      <v-row justify="center">
                        <v-col></v-col>
                        <v-col>
                          <v-img
                            height="150"
                            width="150"
                            src="@/assets/send_failed.png"
                          ></v-img>
                        </v-col>
                        <v-col></v-col>
                      </v-row>
                      <v-card-text class="pb-2">
                        <div class="body-1 mb-1 text--primary">
                          <br />CSVファイルの内容に誤りがあります。
                          <br />以下のボタンよりファイルをダウンロードして
                          <br />詳細を確認してください。
                        </div>
                      </v-card-text>
                      <v-btn
                        @click="downloadCsvErrFile"
                        color="light-green"
                        dark
                        >ダウンロード
                        <v-icon right> mdi-download-circle-outline </v-icon>
                      </v-btn>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="csvInputErr = false"
                          >閉じる</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-overlay :value="overlay">
                    <VueLoading
                      type="spiningDubbles"
                      color="white"
                      :size="{ width: '150px', height: '150px' }"
                    ></VueLoading>
                    <span class="font-weight-bold" style="font-size: 2em">
                      now sending...
                    </span>
                  </v-overlay>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Methods from "@/api/methods";
import AppCombobox from "@/components/AppCombobox";
import { VueLoading } from "vue-loading-template";
import swal from "sweetalert";
import iconv from "iconv-lite";
import encoding from "encoding-japanese";

export default {
  props: {
    source: String,
  },
  components: {
    // shareButton,
    // Loading,
    AppCombobox,
    VueLoading,
  },
  data () {
    return {
      drawer: null,
      tab: null,
      tabitems: [
        { tab: "一括利用", content: "" },
        { tab: "個別利用", content: "" },
      ],
      //ドロップダウン向け
      select: null,
      dropitems: [],
      //サンクスカード贈り先を検索するためのキー初期値
      selectSerchKey: "useMail",
      //サンクスカード送信先相手の検索条件(前方一致)
      usecoinsDestination: "",
      //サンクスカード送信先相手検索結果のレスポンス
      sendCandidate: this.$router.currentRoute.params.member
        ? [this.$router.currentRoute.params.member]
        : [],
      earnCoinMember: this.$router.currentRoute.params.member,
      //サンクスカード送信時のダイアログに出力される贈り先の氏名
      earnCoinMembername: "",

      //サンクスカード送信確認ダイアログの初期表示フラグ
      cardSendConfirmationDialog: false,
      //サンクスギフト送信確認ダイアログの初期表示フラグ
      giftSendConfirmationDialog: false,
      //処理中ローディング画面の初期表示フラグ
      overlay: false,
      //サンクスカード送信成功ダイアログ
      //sendSuccessDialog: false,
      //サンクスギフト送信成功ダイアログ
      sendGiftSuccessDialog: false,
      //サンクスカード送信失敗ダイアログ(mongo処理完了前)
      sendmongofailedDialog: false,
      //サンクスカード送信失敗ダイアログ
      sendfailedDialog: false,
      sendfailedDialog2: false,
      //送信先が自分自身に設定されている場合のアラートダイアログ
      wrongDestinationDialog: false,
      required: (value) => !!value || "贈り先を選択してください", // 入力必須の制約
      isdestination: true,
      //ポイント渡し処理の成否判定
      isusepoint: false,
      isusepointhistory: false,
      isearncoin: false,
      isearncoinhistory: false,
      isenoughPoint: true,
      thanks_message: "",
      useCoins: "",
      isdest_check: false,
      sendpoint: 0,
      logonUserInfo: {},
      giftimage: "thankscard",
      csvValidateErrorMessage: "",
      uploadCsvDialog: false,
      isFileCheck: false,
      csvInputErr: false,
      file: null,
      parsedCsv: null,
      errCsv: null,

      //バリデーションチェック
      valid: false,
      coinRule: [
        (text) => String(text).match(/^-?[0-9]*$/) != null || "数字を入力してください",
      ],
    };
  },
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("admin")).data;
    // const test = JSON.parse(user);
    if (!user) {
      // 未ログイン。ログイン画面へ遷移する
      console.log("未ログイン。ログイン画面へ遷移");
      this.$router.push("/adminsignin");
      return;
    }
    if (user.auth === "not_auth_user") {
      console.log("ユーザ権限無し");
      this.$router.push("/adminNotAuth");
    }
  },
  created () {
    // キャッシュ削除
    if (process.env.NODE_ENV === "production") {
      window.navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      caches.keys().then((keys) => {
        keys.forEach((cacheName) => {
          if (cacheName) {
            caches.delete(cacheName);
          }
        });
      });
    }
  },
  updated () {},
  computed: {},
  watch: {},
  methods: {
    async searchusecoinsDestination (serchInput) {
      //送信先検索処理はメール、社番、氏名を結合した値に対して、前方一致で行われる。
      let response = await Methods.membersearch("admin", serchInput);
      if (response.data === "searchkeyword_null") {
        response.data = [];
      }
      this.sendCandidate = response.data;
    },
    openThanksCardDialog () {
      //宛先未入力かどうかのnullチェック
      if (!this.earnCoinMember) {
        this.isdest_check = false;
        return;
        //ここでリターンしないと次の処理でaccountnumberが参照できずエラーになるため処理を書く
      }
      if (!this.earnCoinMember.membername) {
        this.isdest_check = false;
      } else {
        console.log("earnCoinMemberの中身は：", this.earnCoinMember);
        this.isdest_check = true;
        this.earnCoinMembername = this.earnCoinMember.membername;
      }
    },

    async openCsvUploadDialog () {
      this.parsedCsv = null;
      // CSVファイル選択チェック
      if (this.file === null || this.file === undefined) {
        this.csvValidateErrorMessage =
          "コイン利用情報CSVファイルが未選択です。<br>ファイルを選択して下さい。";
        this.isFileCheck = false;
        return;
      }

      // パースして必要カラムが存在するか
      const csvData = await this.parseCsv(this.file);
      console.log(csvData);
      if (
        csvData.length === 0 ||
        !(
          Object.prototype.hasOwnProperty.call(csvData[0], "社員番号") &&
          Object.prototype.hasOwnProperty.call(csvData[0], "コイン") &&
          Object.prototype.hasOwnProperty.call(csvData[0], "コメント")
        )
      ) {
        this.csvValidateErrorMessage =
          "ファイルの内容が不正です。<br>ファイルを選択しなおして下さい。";
        this.isFileCheck = false;
        return;
      }
      // 100件上限チェック
      if (csvData.length > 100) {
        this.csvValidateErrorMessage =
          "100行を越えるファイルは指定できません。<br>ファイルを選択しなおして下さい。";
        this.isFileCheck = false;
        return;
      }

      // チェックOKであれば実行確認ダイアログを開く
      this.parsedCsv = csvData;
      this.isFileCheck = true;
    },
    parseCsv (file) {
      return new Promise((resolve) => {
        // 文字コード判定のためファイルを読み込み
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onerror = function (e) {
          console.log(e);
          resolve(null);
        };
        const papa = this.$papa;
        reader.onload = async function (e) {
          // SJISの場合は変換する
          const buff = Buffer.from(e.target.result);
          let encodedFile = buff.toString();
          if (encoding.detect(buff) === "SJIS") {
            encodedFile = iconv.decode(buff, "Shift_JIS");
          }
          // CSVをパース
          const results = papa.parse(encodedFile, {
            header: true,
            skipEmptyLines: "greedy",
          });
          resolve(results.data);
        };
      });
    },
    async coinSendFromCsv () {
      try {
        const user = (await Methods.getAuthUserInfo("admin")).data;
        if (!user) {
          console.log("ログイン画面へ遷移");
          this.$router.push("/adminsignin");
          return;
        }
        if (user.auth === "not_auth_user") {
          console.log("ユーザ権限無し");
          this.$router.push("/adminNotAuth");
          return;
        }
        this.uploadCsvDialog = false;
        this.overlay = true;

        // API呼び出し
        const res = await Methods.givecoinfromcsv("admin", {
          adminAccountNumber: user.accountnumber,
          csvInfo: this.parsedCsv,
        });
        console.log(res);
        if (res.data.code === 1) {
          // 入力エラーの場合、ダウンロード用にエラー情報を格納
          this.errCsv = res.data.errCsv;
          this.csvInputErr = true;
          return;
        } else if (res.data.code === -1) {
          this.overlay = false;
          this.sendmongofailedDialog = true;
          return;
        }

        swal({
          title: "コイン一括利用処理が完了！！",
          text: "処理はすべて正常に完了しました。",
          icon: "success",
          button: "閉じる",
        });
      } catch (err) {
        // 送信エラーとなった場合でもエラーメッセージ等は出さない
        await Methods.senderrmail("admin", {
          error: err,
        });
        this.sendmongofailedDialog = true; //送信失敗ダイアログをON
        return;
      } finally {
        this.parsedCsv = null;
        this.file = null;
        this.overlay = false;
      }
    },
    downloadCsvErrFile () {
      // CSV入力エラー詳細ファイルをダウンロードさせる
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, this.errCsv], { type: "text/csv" });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.setAttribute("download", "InputErrorInfo.csv");
      a.click();
      a.remove();
    },
    async coinSend () {
      try {
        const user = (await Methods.getAuthUserInfo("admin")).data;
        if (!user) {
          console.log("ログイン画面へ遷移");
          this.$router.push("/adminsignin");
          return;
        }
        if (user.auth === "not_auth_user") {
          console.log("ユーザ権限無し");
          this.$router.push("/adminNotAuth");
          return;
        }

        this.cardSendConfirmationDialog = false;
        this.overlay = true;

        this.isusepoint = false;
        this.isearncoin = false;
        this.isenoughPoint = true;

        let params = {};
        params.useUserNum = user.accountnumber;
        params.earnUserNum = this.earnCoinMember.accountnumber;
        params.message = this.thanks_message;
        params.coin = this.useCoins;

        let res = await Methods.givecoin("admin", params);
        console.log("返却値は:", res);
        //バックエンドからの戻り値を見て後続処理を実行する
        if (res.data.code === 1) {
          //入力エラー
          this.overlay = false;
          this.cardSendConfirmationDialog = true;
          this.isdest_check = false;
          return;
        } else if (res.data.code === 2) {
          //コイン不足
          this.overlay = false;
          swal({
            title: "コイン割り当て処理が失敗しました",
            text: "対象ユーザのコインが不足しています。",
            icon: "error",
            button: "閉じる",
          });
          return;
        } else if (res.data.code === -1) {
          console.log("コイン送信処理異常終了");
          this.overlay = false;
          this.sendmongofailedDialog = true;
          return;
        } else {
          console.log("コイン送信処理正常終了");
          this.isusepoint = true;
          this.isearncoin = true;
        }

        // 受信者にメール送信を行う
        if (this.isusepoint && this.isearncoin) {
          // 送信エラーとなった場合でもエラーメッセージ等は出さない
          await Methods.sendmail("admin", {
            earnUser: this.earnCoinMember,
            sendUser: { membername: "管理者" },
            type: "coin",
            coin: this.useCoins,
          });
        }

        this.overlay = false;
        //this.sendSuccessDialog = true;
        swal({
          title: "コイン割り当て処理が完了！！",
          text: "処理はすべて正常に完了しました。",
          icon: "success",
          button: "閉じる",
        });
        this.isusepoint = false;
        this.isearncoin = false;

        //入力フォームクリア
        this.earnCoinMember = null; //選択した送信先情報のクリア
        this.earnCoinMembername = "";
        this.sendCandidate = []; //検索結果エリアのクリア
        this.thanks_message = "";
        this.useCoins = "";
      } catch (err) {
        // 送信エラーとなった場合でもエラーメッセージ等は出さない
        await Methods.senderrmail("admin", {
          error: err,
        });
        this.overlay = false; //オーバレイをOFF
        this.sendfailedDialog2 = true; //送信失敗ダイアログをON
        this.isusepoint = false;
        this.isearncoin = false;
        //入力内容初期化
        this.earnCoinMember = null; //選択した送信先情報のクリア
        this.earnCoinMembername = "";
        this.sendCandidate = []; //検索結果エリアのクリア
        this.thanks_message = "";
        this.useCoins = "";
      }
    },
  },
};
</script>

<style></style>
