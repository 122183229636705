// export default router
import Vue from "vue";
import Router from "vue-router";

// @ is an alias to /src
import login from "@/views/login";
import logout from "@/views/logout";
import top from "@/views/Top";
import mypage from "@/views/mypage";
import signin from "@/views/SigninTop";
import notAuth from "@/views/notAuth";
import qrcode from "@/views/qrcode";

import adminSignin from "@/views/admin/adminSignin";
import adminNotAuth from "@/views/admin/adminNotAuth";
import adminTop from "@/views/admin/adminTop";
import pointManage from "@/views/admin/pointManage";
import coinManage from "@/views/admin/coinManage";
import userManage from "@/views/admin/userManage";
import adminPointCoinHistory from "@/views/admin/adminPointCoinHistory";
import usageInquiry from "@/views/admin/usageInquiry";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      component: login,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "none",
      },
      children: [
        {
          path: "",
          name: "signin",
          component: signin,
          meta: {
            layout: "none",
          },
        },
      ],
    },
    {
      path: "/login",
      beforeEnter () {
        window.location =
          location.protocol +
          "//" +
          document.domain +
          process.env.VUE_APP_API_URL_BASE + "user/" +
          "login";
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: logout,
      beforeEnter () {
        window.location =
          location.protocol +
          "//" +
          document.domain +
          process.env.VUE_APP_API_URL_BASE + "auth/" +
          "logout";
      },
    },
    {
      path: "/notAuth",
      name: "notAuth",
      component: notAuth,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "none",
      },
    },
    {
      path: "/top",
      name: "top",
      component: top,
      props: true,
    },
    {
      path: "/mypage",
      name: "mypage",
      component: mypage,
    },
    {
      // QRコード用設定
      path: "/qrcode",
      name: "qrcode",
      component: qrcode,
    },
    {
      path: "/pointmanage",
      name: "pointmanage",
      component: pointManage,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "admin",
      },
    },
    {
      path: "/coinmanage",
      name: "coinmanage",
      component: coinManage,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "admin",
      },
    },
    {
      path: "/adminPointCoinHistory",
      name: "adminPointCoinHistory",
      component: adminPointCoinHistory,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "admin",
      },
    },
    {
      path: "/usageInquiry",
      name: "usageInquiry",
      component: usageInquiry,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "admin",
      },
    },
    {
      path: "/usermanage",
      name: "usermanage",
      component: userManage,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "admin",
      },
    },
    {
      path: "/adminsignup",
      beforeEnter () {
        window.location =
          location.protocol +
          "//" +
          document.domain +
          process.env.VUE_APP_ADMIN_API_URL_BASE + "user/" +
          "login";
      },
    },
    {
      path: "/adminsignin",
      name: "adminsignin",
      component: adminSignin,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "none",
      },
    },
    {
      path: "/adminsignout",
      beforeEnter () {
        window.location =
          location.protocol +
          "//" +
          document.domain +
          process.env.VUE_APP_ADMIN_API_URL_BASE + "auth/" +
          "logout";
      },
    },
    {
      path: "/adminNotAuth",
      name: "adminNotAuth",
      component: adminNotAuth,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "none",
      },
    },
    {
      path: "/admintop",
      name: "admintop",
      component: adminTop,
      meta: {
        // ヘッダーとフッターの表示を消すレイアウトにするために設定
        layout: "admin",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    to.path === "/" ||
    to.path === "" ||
    to.path === "/login" ||
    to.path === "/logout" ||
    to.path === "/notAuth" ||
    to.path === "/top" ||
    to.path === "/mypage" ||
    to.path === "/qrcode" ||
    to.path === "/pointmanage" ||
    to.path === "/coinmanage" ||
    to.path === "/adminPointCoinHistory" ||
    to.path === "/usageInquiry" ||
    to.path === "/usermanage" ||
    to.path === "/adminsignup" ||
    to.path === "/adminsignin" ||
    to.path === "/adminsignout" ||
    to.path === "/adminNotAuth" ||
    to.path === "/admintop"
  ) {
    next();
  } else {
    next({
      path: "/",
    });
  }
});
export default router;
