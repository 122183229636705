<template>
  <div>
    <v-navigation-drawer app v-model="drawer" clipped>
      <!-- タブメニューの見出しを付けるならここに書く -->
      <!-- <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">Navigation lists</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      <v-divider></v-divider>-->
      <v-layout justify-center pt-5 px-4>
        <p class="font-weight-bold mb-2">
          {{ loginUserDepartment }} / {{loginUserName}}
        </p>
      </v-layout>
      <v-list dense nav>
        <v-list-item
          v-for="(nav_list, i) in nav_lists"
          :key="i"
          @click="getCheckURL(nav_list.url)"
        >
          <v-list-item-icon>
            <v-icon>{{ nav_list.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-on:click="movepage">
              {{ nav_list.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white" clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="domain === 'www.soragift.com'">
        <v-img
          width="200px"
          height="auto"
          v-bind:src="require('@/assets/logo_main02.png')"
        ></v-img>
      </v-toolbar-title>
      <v-toolbar-title v-else>
        <v-img
          width="200px"
          height="auto"
          v-bind:src="require('@/assets/logo_main02_dev.png')"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!--ロゴ追加-->
      <!--<v-toolbar-title>
        <v-img
          width="150px"
          height="auto"
          v-bind:src="require('@/assets/xxx.png')"
        ></v-img>
      </v-toolbar-title>-->
    </v-app-bar>
  </div>
</template>

<script>
import Methods from "@/api/methods";

export default {
  props: {
    //userID: Number,
    //userNAME: String,
  },
  data: () => ({
    drawer: null,
    nav_list: 1,
    nav_lists: [
      { name: "マイページ", icon: "mdi-home", url: "/mypage" },
      { name: "サンクスカード/ギフト", icon: "mdi-email", url: "/top" },
      { name: "QRコード", icon: "mdi-qrcode", url: "/qrcode" },
      { name: "ログアウト", icon: "mdi-logout", url: "/logout" },
    ],
    loginuserIcon: "",
    loginUserDepartment: "",
    loginUserName: "",

    //ドメイン格納
    domain: document.domain,
  }),
  async created () {
    console.log("ドメイン：" + document.domain);
    //画面描画前にユーザ情報を確認する
    const user = (await Methods.getAuthUserInfo("user")).data;
    if (user) {
      // ログイン済み
      this.user = user;
    } else {
      return;
    }
    this.idget();

    //会社コードJIT以外に問い合わせメニュー表示
    if (this.user.company === "JIT") {
      this.nav_lists.splice(3, 0, {
        name: "問い合わせ",
        icon: "mdi-chat-question",
        url: "gooleform"
      });
    }
  },
  methods: {
    movepage () {
      // this.$router.push({ name: "mypage" });
    },
    getCheckURL (url) {
      if (url === "gooleform") {
        location.href =
          "https://docs.google.com/forms/d/e/1FAIpQLSdsRwSOMnP5ZntOxI8ghs4l6Mp2HDH0FA7YHjZCZwwYq2AO1Q/viewform?vc=0&c=0&w=1&flr=0&gxids=7628";
      } else {
        this.$router.push({ path: url, query: { t: Date.now() } });
      }
    },
    idget: async function () {
      let empMember = (await Methods.getAuthUserInfo("user")).data;

      this.loginUserDepartment = empMember.department;
      this.loginUserName = empMember.membername;
    },
  },
};
</script>
