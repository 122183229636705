<template>
  <div>
    <v-app id="mypage">
      <v-main>
        <v-container fluid>
          <!-- fluid 幅いっぱいにする -->
          <div>
            <v-row justify="center">
              <v-container>
                <v-row>
                  <v-col class="font-weight-bold pl-3">マイページ</v-col>
                </v-row>

                <v-card>
                  <v-toolbar color="primary" flat>
                    <v-row justify="center">
                      <v-toolbar-title
                        class="font-weight-bold white--text mx-auto"
                      >
                        <span>{{ loginUserCoin.toLocaleString() }} coin</span>
                        <span class="ml-4">残 {{ loginUserPoint }} pt</span>
                      </v-toolbar-title>
                      <v-icon
                        color="white"
                        class="ml-n14"
                        size="30"
                        @click="reload"
                      >
                        mdi-refresh
                      </v-icon>
                    </v-row>
                  </v-toolbar>

                  <!-- 新着表示 -->
                  <v-row class="pt-3" justify="center">
                    <vcol class="font-weight-bold">NEW</vcol>
                  </v-row>
                  <!-- <v-row class="pt-3 pl-3">
                    <vcol class="font-weight-bold pl-3">今月</vcol>
                  </v-row> -->
                  <v-row align="center">
                    <!-- alignが縦方向 -->
                    <v-col cols="3" class="pt-0">
                      <v-img
                        v-bind:src="require('@/assets/newgift.png')"
                        height="100"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col cols="3" style="font-size: 2em" class="pt-0">
                      <p class="font-weight-bold">
                        {{ countNewGift.length }}枚
                      </p>
                    </v-col>
                    <v-col cols="3" class="pt-0">
                      <v-img
                        v-bind:src="require('@/assets/newcard.png')"
                        height="100"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col cols="3" style="font-size: 2em" class="pt-0">
                      <p class="font-weight-bold">
                        {{ countNewCard.length }}枚
                      </p>
                    </v-col>
                  </v-row>
                  <!-- 新着表示終了 -->
                  <v-divider></v-divider>
                  <!-- 今月枚数表示 -->
                  <v-row class="pt-3" justify="center">
                    <vcol class="font-weight-bold">今月</vcol>
                  </v-row>
                  <!-- <v-row class="pt-3 pl-3">
                    <vcol class="font-weight-bold pl-3">今月</vcol>
                  </v-row> -->
                  <v-row align="center">
                    <!-- alignが縦方向 -->
                    <v-col cols="3" class="pt-0 pb-0 pl-4 pr-2">
                      <v-img
                        v-bind:src="require('@/assets/receivecard.png')"
                        height="100"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col cols="3" style="font-size: 2em" class="pt-0 pb-0">
                      <p class="font-weight-bold">{{ howmany_received }}枚</p>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0 pl-4 pr-2">
                      <v-img
                        v-bind:src="require('@/assets/sendcard.png')"
                        height="100"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col cols="3" style="font-size: 2em" class="pt-0 pb-0">
                      <p class="font-weight-bold">{{ howmany_send }}枚</p>
                    </v-col>
                  </v-row>
                  <!-- 今月枚数表示 -->

                  <v-row justify="center">
                    <v-col class="mb-5 mt-0 pt-0">
                      <v-btn to="/top" color="primary" elevation="2"
                        >サンクスカード/ギフトを送る</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>
            </v-row>
          </div>
        </v-container>

        <v-container>
          <v-list>
            <v-card>
              <v-tabs v-model="tab" background-color="primary" dark grow>
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab
                  v-for="item in historytabitems"
                  :key="item.tab"
                  class="font-weight-bold"
                  >{{ item.tab }}</v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in historytabitems" :key="item.tab">
                  <v-card flat>
                    <!--ポイントをもらった履歴画面-->
                    <div v-if="item.tab === 'もらった'">
                      <v-container>
                        <v-row dense justify="start">
                          <v-chip-group
                            v-model="historyFilterRecv"
                            column
                            @change="changeHistoryFilterRecv"
                          >
                            <v-chip
                              filter
                              outlined
                              value="gift"
                              class="mr-1 px-2"
                            >
                              ギフト
                            </v-chip>
                            <v-chip
                              filter
                              outlined
                              value="card"
                              class="mr-1 px-2"
                            >
                              カード
                            </v-chip>
                            <v-chip
                              filter
                              outlined
                              value="admin"
                              class="mr-1 px-2"
                            >
                              管理者
                            </v-chip>
                          </v-chip-group>
                          <v-spacer></v-spacer>
                          <SearchHistoryDialog
                            class="mr-1 mt-2"
                            :searchType="searchTypeFrom"
                            @searchHistory="searchHistory"
                          />
                        </v-row>
                        <v-row dense justify="space-between" class="mt-1">
                          <v-col cols="4">
                            <v-btn class="mr-1" @click="changeSortFrom">
                              <v-icon v-if="orderByFrom === 'desc'" left
                                >mdi-sort-ascending</v-icon
                              >
                              <v-icon v-else-if="orderByFrom === 'asc'" left
                                >mdi-sort-descending</v-icon
                              >
                              {{ orderStringFrom }}
                            </v-btn>
                          </v-col>
                          <v-col cols="4">
                            <div style="text-align: right">
                              <span style="font-size: 1.5em" class="pl-1"
                                >{{ fromAllPagesDatas.length }} 枚</span
                              >
                            </div>
                          </v-col>
                        </v-row>
                        <v-row dense justify="start">
                          <v-col
                            v-for="fromDisplayData in fromDisplayDatas"
                            :key="`${fromDisplayData.date}-${fromDisplayData.order}`"
                            cols="12"
                            @click="
                              showDetails('receivehistory', fromDisplayData)
                            "
                          >
                            <v-card outlined flat hover>
                              <div
                                class="d-flex flex-no-wrap justify-space-around"
                              >
                                <div>
                                  <v-card-title
                                    v-if="fromDisplayData.new === true"
                                    class="mb-n10 red--text"
                                    >New
                                  </v-card-title>
                                  <v-card-title class="mb-n10"
                                    >FROM:
                                  </v-card-title>
                                  <v-card-title>{{
                                    fromDisplayData.fromDepartment +
                                    "  " +
                                    fromDisplayData.fromName
                                  }}</v-card-title>
                                  <v-card-text
                                    v-if="
                                      fromDisplayData.picturetype === 'putpoint'
                                    "
                                    class="blue--text text-left mb-n3"
                                    style="font-size: 2em"
                                    >{{
                                      fromDisplayData.point + "point"
                                    }}</v-card-text
                                  >
                                  <v-card-text
                                    v-else
                                    class="blue--text text-left mb-n3"
                                    style="font-size: 2em"
                                    >{{
                                      fromDisplayData.coin + "coin"
                                    }}</v-card-text
                                  >
                                  <v-card-text class="text-left my-n3">{{
                                    fromDisplayData.date
                                  }}</v-card-text>
                                </div>
                                <v-layout
                                  class="justify-end align-center mr-2"
                                  v-if="
                                    fromDisplayData.message.length >= 1 &&
                                    fromDisplayData.picturetype !==
                                      'thankscard' &&
                                    fromDisplayData.picturetype !== 'putcoin' &&
                                    fromDisplayData.picturetype !== 'putpoint'
                                  "
                                >
                                  <v-badge
                                    icon="mdi-message-text"
                                    color="blue darken-2"
                                    overlap
                                  >
                                    <v-avatar size="100" tile>
                                      <img
                                        :src="
                                          require(`@/assets/${fromDisplayData.picturetype}.png`)
                                        "
                                      />
                                    </v-avatar>
                                  </v-badge>
                                </v-layout>

                                <v-layout
                                  class="justify-end align-center mr-2"
                                  v-else
                                >
                                  <v-avatar size="100" tile>
                                    <img
                                      :src="
                                        require(`@/assets/${fromDisplayData.picturetype}.png`)
                                      "
                                    />
                                  </v-avatar>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                      <div class="text-center">
                        <v-pagination
                          v-model="rec_history_page"
                          :length="rec_page_length"
                          circle
                          @input="rec_pageChange"
                        ></v-pagination>
                      </div>
                    </div>
                  </v-card>
                  <!--ポイントをもらった履歴画面終わり-->
                  <v-card flat>
                    <!--ポイントを渡した履歴画面-->
                    <div v-if="item.tab === 'おくった'">
                      <v-container>
                        <v-row dense justify="start">
                          <v-chip-group
                            v-model="historyFilterSend"
                            column
                            @change="changeHistoryFilterSend"
                          >
                            <v-chip
                              filter
                              outlined
                              value="gift"
                              class="mr-1 px-2"
                            >
                              ギフト
                            </v-chip>
                            <v-chip
                              filter
                              outlined
                              value="card"
                              class="mr-1 px-2"
                            >
                              カード
                            </v-chip>
                          </v-chip-group>
                          <v-spacer></v-spacer>
                          <SearchHistoryDialog
                            class="mr-1 mt-2"
                            :searchType="searchTypeTo"
                            @searchHistory="searchHistory"
                          />
                        </v-row>
                        <v-row
                          dense
                          justify="space-between"
                          class="mt-1"
                          align-content="center"
                        >
                          <v-col cols="4">
                            <v-btn class="mr-1" @click="changeSortTo">
                              <v-icon v-if="orderByTo === 'desc'" left
                                >mdi-sort-ascending</v-icon
                              >
                              <v-icon v-else-if="orderByTo === 'asc'" left
                                >mdi-sort-descending</v-icon
                              >
                              {{ orderStringTo }}
                            </v-btn>
                          </v-col>
                          <v-col cols="4">
                            <div style="text-align: right">
                              <span style="font-size: 1.5em" class="pl-1"
                                >{{ sendAllPagesDatas.length }} 枚</span
                              >
                            </div>
                          </v-col>
                        </v-row>
                        <v-row dense justify="start">
                          <v-col
                            cols="12"
                            v-for="sendHistoryData in sendDisplayDatas"
                            :key="`${sendHistoryData.date}-${sendHistoryData.order}`"
                            @click="showDetails('sendhistory', sendHistoryData)"
                          >
                            <v-card outlined flat hover>
                              <div
                                class="d-flex flex-no-wrap justify-space-around"
                              >
                                <div>
                                  <v-card-title class="mb-n10"
                                    >TO:
                                  </v-card-title>
                                  <v-card-title>{{
                                    sendHistoryData.toDepartment +
                                    "  " +
                                    sendHistoryData.toName
                                  }}</v-card-title>
                                  <v-card-text
                                    class="blue--text text-left mb-n3"
                                    style="font-size: 2em"
                                    >{{
                                      sendHistoryData.point + "coin"
                                    }}</v-card-text
                                  >
                                  <v-card-text class="text-left my-n3">{{
                                    sendHistoryData.date
                                  }}</v-card-text>
                                </div>
                                <v-layout
                                  class="justify-end align-center mr-2"
                                  v-if="
                                    sendHistoryData.message.length >= 1 &&
                                    sendHistoryData.picturetype !== 'thankscard'
                                  "
                                >
                                  <v-badge
                                    icon="mdi-message-text"
                                    color="blue darken-2"
                                    overlap
                                  >
                                    <v-avatar size="100" tile>
                                      <img
                                        :src="
                                          require(`@/assets/${sendHistoryData.picturetype}.png`)
                                        "
                                      />
                                    </v-avatar>
                                  </v-badge>
                                </v-layout>

                                <v-layout
                                  class="justify-end align-center mr-2"
                                  v-else
                                >
                                  <v-avatar size="100" tile>
                                    <img
                                      :src="
                                        require(`@/assets/${sendHistoryData.picturetype}.png`)
                                      "
                                    />
                                  </v-avatar>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                      <div class="text-center">
                        <v-pagination
                          v-model="send_history_page"
                          :length="send_page_length"
                          circle
                          @input="send_pageChange"
                        ></v-pagination>
                      </div>
                    </div>
                  </v-card>
                  <!--ポイントを渡した履歴画面終わり-->
                </v-tab-item>
              </v-tabs-items>
            </v-card>
            <!--ポイントをもらった履歴画面のダイアログ-->
            <template v-if="tab === 0">
              <v-dialog v-model="ThanksGiftCardDetailsfrom" max-width="400">
                <v-card>
                  <v-row dense>
                    <v-col cols="12">
                      <v-layout justify-center align-center>
                        <v-img
                          height="80"
                          width="auto"
                          :contain="true"
                          :src="titleImage[sendtype]"
                          class="my-5"
                        ></v-img>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" align="start">
                      <v-card-text class="body-1 font-weight-bold py-0">
                        <span class="Detail_column">FROM</span>
                        {{ detail_from }} さん
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" align="start">
                      <v-card-text class="body-1 font-weight-bold py-0">
                        <span class="Detail_column">DATE</span>
                        {{ detail_date_from }}
                      </v-card-text>
                    </v-col>
                    <template v-if="sendtype === 'thankscard'">
                      <v-col cols="12" align="start">
                        <v-card-text class="body-1">
                          <span class="Detail_column">JALフィロソフィ</span
                          ><br />
                          {{ detail_philosophy_from }}
                        </v-card-text>
                      </v-col>
                      <v-col cols="12" align="start">
                        <v-card-text
                          class="body-1 py-0"
                          style="white-space: pre-line"
                        >
                          <span class="Detail_column">サンクスコメント</span
                          ><br />
                          {{ detail_message_from }}
                        </v-card-text>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="12" align="start">
                        <v-card-text
                          class="body-1 py-0"
                          style="white-space: pre-line"
                        >
                          <span class="Detail_column">コメント</span><br />
                          {{ detail_message_from }}
                        </v-card-text>
                      </v-col>
                    </template>
                  </v-row>
                  <v-card-actions class="pa-3 d-block">
                    <v-row justify-center align-center class="pa-3 d-block">
                      <div
                        v-if="
                          detailUserData.picturetype !== 'putpoint' &&
                          detailUserData.picturetype !== 'putcoin'
                        "
                      >
                        <v-btn
                          color="red lighten-2"
                          class="ma-3 white--text"
                          @click="giftReceiverSet(detailUserData)"
                        >
                          ギフト
                          <v-icon class="white--text" right> mdi-gift-outline </v-icon>
                        </v-btn>
                        <v-btn
                          color="primary"
                          class="ma-3 white--text"
                          @click="likedMailSend(detailUserData)"
                        >
                          いいね!
                          <v-icon class="white--text" right> mdi-thumb-up </v-icon>
                        </v-btn>
                      </div>
                    </v-row>
                    <v-row no-gutters justify-center align-center>
                      <v-col cols="9" />
                      <v-col cols="3">
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="ThanksGiftCardDetailsfrom = false"
                          >閉じる
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
                <v-overlay :value="sendMailOverlay">
                  <VueLoading
                    type="spiningDubbles"
                    color="white"
                    :size="{ width: '150px', height: '150px' }"
                  ></VueLoading>
                  <span class="font-weight-bold" style="font-size: 2em">
                    now sending...
                  </span>
                </v-overlay>
                <v-overlay :value="sendGiftOverlay">
                  <VueLoading
                    type="spiningDubbles"
                    color="white"
                    :size="{ width: '150px', height: '150px' }"
                  ></VueLoading>
                </v-overlay>
                <v-dialog v-model="sendMailGiftResultDialog" max-width="400">
                  <v-card height="auto" max-width="auto" outlined>
                    <v-row justify="center">
                      <v-card-title class="headline">
                        <div><br />{{ sendMailGiftResultTitle }}</div>
                      </v-card-title>
                      <v-card-text style="white-space: pre-line">
                        {{ sendMailGiftResultText }}<br />
                      </v-card-text>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="ThanksGiftCardDetailsfrom = false"
                        >閉じる</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-dialog>
            </template>
            <!--ポイントをもらった履歴画面のダイアログ終わり-->

            <!--ポイントをわたした履歴画面のダイアログ-->
            <template v-if="tab === 1">
              <v-dialog v-model="ThanksGiftCardDetailsto" max-width="400">
                <v-card>
                  <v-row dense>
                    <v-col cols="12">
                      <v-layout justify-center align-center>
                        <v-img
                          height="80"
                          width="auto"
                          :contain="true"
                          :src="titleImage[sendtype]"
                          class="my-5"
                        ></v-img>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" align="start">
                      <v-card-text class="body-1 font-weight-bold py-0">
                        <span class="Detail_column">TO</span>
                        {{ detail_to }} さん
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" align="start">
                      <v-card-text class="body-1 font-weight-bold py-0">
                        <span class="Detail_column">DATE </span>
                        {{ detail_date_to }}
                      </v-card-text>
                    </v-col>
                    <template v-if="sendtype === 'thankscard'">
                      <v-col cols="12" align="start">
                        <v-card-text class="body-1">
                          <span class="Detail_column">JALフィロソフィ</span
                          ><br />
                          {{ detail_philosophy_to }}
                        </v-card-text>
                      </v-col>
                      <v-col cols="12" align="start">
                        <v-card-text
                          class="body-1 py-0"
                          style="white-space: pre-line"
                        >
                          <span class="Detail_column">サンクスコメント</span
                          ><br />
                          {{ detail_message_to }}
                        </v-card-text>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="12" align="start">
                        <v-card-text
                          class="body-1 py-0"
                          style="white-space: pre-line"
                        >
                          <span class="Detail_column">コメント</span><br />
                          {{ detail_message_to }}
                        </v-card-text>
                      </v-col>
                    </template>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="ThanksGiftCardDetailsto = false"
                      >閉じる
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <!--ポイントをわたした履歴画面のダイアログ終わり-->
          </v-list>
        </v-container>
        <v-overlay :value="overlay">
          <VueLoading
            type="spiningDubbles"
            color="white"
            :size="{ width: '150px', height: '150px' }"
          ></VueLoading>
          <span class="font-weight-bold" style="font-size: 2em">
            now searching...
          </span>
        </v-overlay>
      </v-main>
    </v-app>
  </div>
</template>

<script type="module">
import Methods from "@/api/methods";
import moment from "moment";
import SearchHistoryDialog from "../components/SearchHistoryDialog.vue";
import { VueLoading } from "vue-loading-template";

export default {
  props: {
    source: String,
  },
  components: {
    SearchHistoryDialog,
    VueLoading,
  },
  data: () => ({
    drawer: null,
    tab: null,
    historytabitems: [
      { tab: "もらった", content: "" },
      { tab: "おくった", content: "" },
    ],
    //ログインユーザの情報
    // loginUserID: "",
    loginUserDepartment: "",
    loginUserName: "",
    loginUserCoin: 0,
    loginUserPoint: 0,
    loginUserPhoto: "",
    loginUserNumber: "",
    sendDisplayDatas: [],
    fromDisplayDatas: [],
    sendAllPagesDatas: [],
    fromAllPagesDatas: [],
    earncoinArray: [],
    //詳細画面の制御
    ThanksGiftCardDetailsfrom: false,
    ThanksGiftCardDetailsto: false,
    detail_to: "",
    detail_from: "",
    detail_date_from: "",
    detail_date_to: "",
    detail_message_from: "",
    detail_message_to: "",
    detail_philosophy_from: "",
    detail_philosophy_to: "",
    detailUserData: {},
    howmany_received: 0,
    howmany_send: 0,
    countNewItem: [],
    countNewGift: [],
    countNewCard: [],
    newData: "",
    sendtype: null,
    titleImage: {
      thankscard: require("@/assets/thankscard_tittle.png"),
      putcoin: require("@/assets/putcoin.png"),
      putpoint: require("@/assets/putpoint.png"),
      arigato: require("@/assets/arigato.png"),
      maemuki: require("@/assets/maemuki.png"),
      tasukatta: require("@/assets/tasukatta.png"),
      yoroshiku: require("@/assets/yoroshiku.png"),
      omedeto: require("@/assets/omedeto.png"),
      niceidea: require("@/assets/niceidea.png"),
      sugoine: require("@/assets/sugoine.png"),
      shigotogahayai: require("@/assets/shigotogahayai.png"),
      batontouch: require("@/assets/batontouch.png"),
      kando: require("@/assets/kando.png"),
      goodjob: require("@/assets/goodjob.png"),
      ooda: require("@/assets/ooda.png"),
      qrcode: require("@/assets/qrcode.png")
    },
    //いいねメール送信結果ダイアログ
    sendMailGiftResultDialog: false,
    sendMailGiftResultTitle: "",
    sendMailGiftResultText: "",
    //ページネーション用
    rec_history_page: 1,
    send_history_page: 1,
    rec_page_length: 0,
    send_page_length: 0,
    pageSize: 5,
    //ページネーションしたいのでここで定義
    sendHistory: [],
    receiveHistory: [],
    //処理中ローディング画面の初期表示フラグ
    overlay: false,
    sendMailOverlay: false,
    sendGiftOverlay: false,
    historyFilterRecv: "",
    historyFilterSend: "",
    searchTypeFrom: "FROM",
    searchTypeTo: "TO",
    orderByFrom: "desc",
    orderStringFrom: "新しい順",
    orderByTo: "desc",
    orderStringTo: "新しい順",
  }),
  async beforeCreate () {
    const user = (await Methods.getAuthUserInfo("user")).data;
    if (!user) {
      // 未ログインもしくは管理者用ユーザでログイン状態。ログイン画面へ遷移する
      this.$router.push("/");
      return;
    }
    if (user.auth === "not_auth_user") {
      console.log("ユーザ権限無し");
      this.$router.push("/notAuth");
      return;
    }
    this.user = user;
  },
  created () {
    // キャッシュ削除
    if (process.env.NODE_ENV === "production") {
      window.navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      caches.keys().then((keys) => {
        keys.forEach((cacheName) => {
          if (cacheName) {
            caches.delete(cacheName);
          }
        });
      });
    }
    this.idget();
  },
  async mounted () {
    await this.get_history();
  },
  updated () {
    //同一コンポーネント内のmethods内のメソッドを出す場合はthisで呼べる
  },
  computed: {},
  //Sprint21 SORAGIFT-1576バグ修正にてWatch中身削除
  watch: {},
  methods: {
    rec_pageChange: function (recPageNumber) {
      this.fromDisplayDatas = this.fromAllPagesDatas.slice(
        this.pageSize * (recPageNumber - 1),
        this.pageSize * recPageNumber
      );
    },
    send_pageChange: function (sendPageNumber) {
      this.sendDisplayDatas = this.sendAllPagesDatas.slice(
        this.pageSize * (sendPageNumber - 1),
        this.pageSize * sendPageNumber
      );
    },
    //Sprint15改修：取得元BC→MongoDB
    async get_history () {
      let loginUser = (await Methods.getAuthUserInfo("user")).data;
      this.loginUserNumber = loginUser.accountnumber;

      let date = new Date();

      let nowyear = date.getFullYear();
      let lastyear = nowyear - 1;

      //TOタブに表示する情報取得
      //去年分～今年分取得
      for (let tYear = lastyear; tYear <= nowyear; tYear++) {
        let sendHistoryKey = "K:S:" + this.loginUserNumber + ":0:" + tYear;
        //year年分
        let eachSendHistory = await Methods.getUsePointHistory(
          "user",
          sendHistoryKey
        );

        //エラー判定（year年の履歴情報無しの場合、エラーの場合）
        if (
          Object.keys(eachSendHistory.data.history).length === 0 ||
          eachSendHistory.data.error
        ) {
          //もし履歴無しが今年の場合、今月の送付数も0に設定
          if (tYear === nowyear) {
            this.howmany_send = 0;
          }
          // 1件履歴がもない場合は次の年へ
          continue;
        }
        // 取得結果の履歴部分のみを格納
        let toHistory = eachSendHistory.data.history[0].history;
        // 取得結果をマージ
        this.sendHistory = this.sendHistory.concat(toHistory);
        if (tYear === nowyear) {
          //howmany_sendには先月以前に送ったものが除外された枚数情報が格納される。
          this.howmany_send = eachSendHistory.data.monthlyCount;
        }
      }

      //TODO:ブロックチェーン側から取得した値のエラー処理
      if (this.sendHistory.length >= 1) {
        //TO履歴画面に出す情報を配列でv-forに渡す//こにif文いる？
        this.sendAllPagesDatas = this.sortHistory("desc", this.sendHistory);
        this.send_page_length = Math.ceil(
          this.sendAllPagesDatas.length / this.pageSize
        );
        this.sendDisplayDatas = this.sendAllPagesDatas.slice(0, this.pageSize);
      }

      //FROMタブに表示する情報取得
      for (let fYear = lastyear; fYear <= nowyear; fYear++) {
        let receiveHistoryKey = "K:R:" + this.loginUserNumber + ":0:" + fYear;
        //各年の履歴情報取得
        let eachReceiveHistory = await Methods.getUsePointHistory(
          "user",
          receiveHistoryKey
        );

        //エラー判定
        if (
          Object.keys(eachReceiveHistory.data.history).length === 0 ||
          eachReceiveHistory.data.error
        ) {
          //もし履歴無しが今年の場合、今月の受信数も0に設定
          if (fYear === nowyear) {
            this.howmany_received = 0;
          }
          // 1件履歴がもない場合は次の年へ
          continue;
        }
        // 取得結果をマージ
        let fromHistory = eachReceiveHistory.data.history[0].history;
        this.receiveHistory = this.receiveHistory.concat(fromHistory);
        if (fYear === nowyear) {
          //howmany_receivedには先月以前に送ったものが除外された枚数情報が格納される。
          this.howmany_received = eachReceiveHistory.data.monthlyCount;
        }
      }

      if (this.receiveHistory.length >= 1) {
        //新着情報
        //ローカルストレージに保存するアクセス日時用の変数
        let lastDisplayDate = "";

        //localstorageを用いて、前回のアクセス日時を保存
        //localstorageにデータがない場合（初期アクセス時）
        if (!localStorage.getItem("lastDisplayDate")) {
          //取得した履歴データから、管理者付与分を除外する
          this.countNewItem = this.receiveHistory.filter(
            (i) => !["putcoin", "putpoint"].includes(i.picturetype)
          );
          //抽出したデータから、サンクスギフト分を抽出する
          this.countNewGift = this.countNewItem.filter(
            (i) => !["thankscard"].includes(i.picturetype)
          );
          console.log(this.countNewGift);
          //抽出したデータから、サンクスカード分を抽出する
          this.countNewCard = this.countNewItem.filter((i) =>
            ["thankscard"].includes(i.picturetype)
          );
          //アクセス日時をローカルストレージへ保存
          //lastDisplayDate = moment(date).format("YYYY/MM/DD HH:mm");
          localStorage.setItem(
            "lastDisplayDate",
            moment(date).format("YYYY/MM/DD HH:mm")
          );
        } else {
          //localstorageにデータがある場合
          //localStorageに保存されているアクセス日時を取得する
          lastDisplayDate = localStorage.getItem("lastDisplayDate");

          //取得したアクセス日時と履歴データの日時を比較し、新着データを抽出する
          if (this.receiveHistory.length > 0) {
            this.countNewItem = this.receiveHistory.filter(
              (i) => i.date >= lastDisplayDate
            );
          }
          //抽出した新着データから、管理者付与分を除外する
          this.countNewItem = this.countNewItem.filter(
            (i) => !["putcoin", "putpoint"].includes(i.picturetype)
          );

          //抽出したデータから、サンクスギフト分を抽出する
          this.countNewGift = this.countNewItem.filter(
            (i) => !["thankscard"].includes(i.picturetype)
          );

          //抽出したデータから、サンクスカード分を抽出する
          this.countNewCard = this.countNewItem.filter((i) =>
            ["thankscard"].includes(i.picturetype)
          );

          //処理終了時に、もう一度アクセス日時を取得し、localstorageへ保存する
          //lastDisplayDate = moment(date).format("YYYY/MM/DD HH:mm");
          localStorage.setItem(
            "lastDisplayDate",
            moment(date).format("YYYY/MM/DD HH:mm")
          );
        }

        //ここでのifはいらない？→ここはそもそもlength!==0のときの処理。
        if (this.receiveHistory.length > 0) {
          for (let index = 0; index < this.receiveHistory.length; index++) {
            //履歴データの受信日時とアクセス日時を比較し、新着物のnewフラグをtrueにする
            if (this.receiveHistory[index].date >= lastDisplayDate) {
              this.receiveHistory[index].new = true;
            } else {
              //それ以外の物はfalseにする
              this.receiveHistory[index].new = false;
            }
          }
          //FROM履歴画面に出す情報を配列でv-forに渡す
          this.fromAllPagesDatas = this.sortHistory(
            "desc",
            this.receiveHistory
          );
        }
      }

      this.rec_page_length = Math.ceil(
        this.fromAllPagesDatas.length / this.pageSize
      );
      this.fromDisplayDatas = this.fromAllPagesDatas.slice(0, this.pageSize);
    },
    idget: async function () {
      let empMember = (await Methods.getAuthUserInfo("user")).data;

      this.loginUserDepartment = empMember.department;
      this.loginUserName = empMember.membername;
      this.loginUserCoin = empMember.coin;
      this.loginUserPoint = empMember.point;
    },
    async showDetails (historytype, historyDataDetail) {
      //適切な詳細ダイアログを表示するための処理
      this.sendtype = historyDataDetail.picturetype;

      if (historytype === "sendhistory") {
        this.ThanksGiftCardDetailsto = true;

        this.detail_to = historyDataDetail.toName;
        this.detail_date_to = historyDataDetail.date;
        this.detail_message_to = historyDataDetail.message;
        this.detail_philosophy_to = historyDataDetail.philosophy;
      }
      if (historytype === "receivehistory") {
        this.ThanksGiftCardDetailsfrom = true;

        this.detail_from = historyDataDetail.fromName;
        this.detail_date_from = historyDataDetail.date;
        this.detail_message_from = historyDataDetail.message;
        this.detail_philosophy_from = historyDataDetail.philosophy;
        this.detailUserData = historyDataDetail;
      }
    },
    changeHistoryFilterRecv () {
      let filterdHistory = [];
      if (this.receiveHistory) {
        filterdHistory = this.changeHistoryFilter(
          this.receiveHistory,
          this.historyFilterRecv
        );
      }
      this.fromAllPagesDatas = filterdHistory;
      // ページングクリア
      this.rec_history_page = 1;
      this.fromDisplayDatas = filterdHistory.slice(0, this.pageSize);
      this.rec_page_length = Math.ceil(filterdHistory.length / this.pageSize);
    },
    changeHistoryFilterSend () {
      let filterdHistory = [];
      if (this.sendHistory) {
        filterdHistory = this.changeHistoryFilter(
          this.sendHistory,
          this.historyFilterSend
        );
      }
      this.sendAllPagesDatas = filterdHistory;
      // ページングクリア
      this.send_history_page = 1;
      this.sendDisplayDatas = filterdHistory.slice(0, this.pageSize);
      this.send_page_length = Math.ceil(filterdHistory.length / this.pageSize);
    },
    changeHistoryFilter (historyListOrigin, historyFilter) {
      if (historyFilter === undefined || historyFilter === "") {
        // フィルター設定がない場合はすべて表示
        return historyListOrigin;
      }
      let filterdHistory = [];
      if (historyFilter === "gift") {
        filterdHistory = historyListOrigin.filter(
          (i) => !["thankscard", "putcoin", "putpoint"].includes(i.picturetype)
        );
      } else if (historyFilter === "card") {
        filterdHistory = historyListOrigin.filter((i) =>
          ["thankscard"].includes(i.picturetype)
        );
      } else {
        filterdHistory = historyListOrigin.filter((i) =>
          ["putcoin", "putpoint"].includes(i.picturetype)
        );
      }
      return filterdHistory;
    },
    async searchHistory (searchType, searchCond) {
      console.log("searchCond", searchCond);
      let searchResult = [];
      const now = new Date();

      // オーバレイをON
      this.overlay = true;

      // 検索対象はデフォルトで今年、昨年の2年分
      let getFromYear = now.getFullYear() - 1;
      let getToYear = now.getFullYear();

      // 検索条件が指定されていた場合はそちらを使用
      if (searchCond.fromYear !== "") {
        getFromYear = searchCond.fromYear;
      } else if (searchCond.fromYear === "" && searchCond.toYear !== "") {
        // 年月TOが指定されていて年月FROMが指定されていない場合は今年含めた5年分を検索対象とする
        getFromYear = now.getFullYear() - 4;
      }
      if (searchCond.toYear !== "") {
        getToYear = searchCond.toYear;
      }

      // 指定年数分繰り返しAPI呼び出し
      const keyType = searchType === "FROM" ? "R" : "S";
      for (let year = getFromYear; year <= getToYear; year++) {
        const historyKey =
          "K:" + keyType + ":" + this.loginUserNumber + ":0:" + year;
        const historyOneYear = await Methods.getUsePointHistory(
          "user",
          historyKey
        );
        console.log(
          "historyOneYear：" + JSON.stringify(historyOneYear, null, 2)
        );
        // エラー判定
        if (historyOneYear.data.error) {
          // エラーダイアログ表示
          this.overlay = false;
          return;
        }
        // 取得結果をマージ
        if (historyOneYear.data.history.length > 0) {
          searchResult = searchResult.concat(
            historyOneYear.data.history[0].history
          );
        }
      }
      console.log("searchResult", searchResult);
      // 取得結果から検索条件で絞り込む
      searchResult = await this.filterDetailSearch(
        searchType,
        searchCond,
        searchResult
      );

      if (searchType === "FROM") {
        // 簡単検索を再実行
        this.receiveHistory = searchResult;
        this.changeHistoryFilterRecv();
        // ソートを新しい順にする
        this.orderByFrom = "asc";
        this.changeSortFrom();
      } else {
        this.sendHistory = searchResult;
        this.changeHistoryFilterSend();
        // ソートを新しい順にする
        this.orderByTo = "asc";
        this.changeSortTo();
      }

      // オーバレイをOFF
      this.overlay = false;
    },
    async filterDetailSearch (searchType, searchCond, historyList) {
      const accountNumberKey = searchType === "FROM" ? "from" : "to";

      console.log("historyList：" + JSON.stringify(historyList, null, 2));

      let filterdList = [];
      // 指定されたユーザーで絞り込み
      if (searchCond.users.length > 0) {
        // ユーザーが指定された時点で管理者からの付与は除外
        historyList = historyList.filter(
          (history) => !["putcoin", "putpoint"].includes(history.picturetype)
        );
        for (const user of searchCond.users) {
          const filterdUser = historyList.filter(
            (history) => history[accountNumberKey] === user.accountnumber
          );
          filterdList = filterdList.concat(filterdUser);
        }
      } else {
        // 条件指定がない場合は全件表示対象とする
        filterdList = filterdList.concat(historyList);
      }
      console.log("filterdList：" + JSON.stringify(filterdList, null, 2));
      // 年月で絞り込み
      if (searchCond.fromYear !== "" && searchCond.fromMonth !== "") {
        filterdList = filterdList.filter(
          (history) =>
            history.date.substr(0, 7) >=
            searchCond.fromYear + "/" + searchCond.fromMonth.padStart(2, "0")
        );
      }
      if (searchCond.toYear !== "" && searchCond.toMonth !== "") {
        filterdList = filterdList.filter(
          (history) =>
            history.date.substr(0, 7) <=
            searchCond.toYear + "/" + searchCond.toMonth.padStart(2, "0")
        );
      }
      return filterdList;
    },
    changeSortFrom () {
      if (this.orderByFrom === "desc") {
        this.orderByFrom = "asc";
        this.orderStringFrom = "古い順";
      } else {
        this.orderByFrom = "desc";
        this.orderStringFrom = "新しい順";
      }
      // ソートする
      const sortedList = this.sortHistory(
        this.orderByFrom,
        this.fromAllPagesDatas
      );
      // ページングクリア
      this.rec_history_page = 1;
      this.fromDisplayDatas = sortedList.slice(0, this.pageSize);
    },
    changeSortTo () {
      if (this.orderByTo === "desc") {
        this.orderByTo = "asc";
        this.orderStringTo = "古い順";
      } else {
        this.orderByTo = "desc";
        this.orderStringTo = "新しい順";
      }
      // ソートする
      const sortedList = this.sortHistory(
        this.orderByTo,
        this.sendAllPagesDatas
      );
      // ページングクリア
      this.send_history_page = 1;
      this.sendDisplayDatas = sortedList.slice(0, this.pageSize);
    },
    sortHistory (orderBy, historyList) {
      return historyList.sort(function (a, b) {
        if (orderBy === "desc") {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        } else {
          if (a.date < b.date) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    },
    reload () {
      // ページリロード
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    //いいねメール送信
    async likedMailSend (sendMailData) {
      console.log("メール送信先ユーザー" + JSON.stringify(sendMailData));
      this.sendMailOverlay = true;
      //カードorギフト確認
      let type = null;
      if (sendMailData.picturetype === "thankscard") {
        type = "card";
      } else {
        type = "gift";
      }
      console.log("カードorギフト：" + type);
      try {
        //いいねメールを送信する
        let result = await Methods.sendLikedMail("user", {
          recvUser: sendMailData.from,
          sendUser: sendMailData.to,
          type: type,
          date: sendMailData.date,
        });
        let code = result.data.code;

        if (code === 0) {
          //メール送信完了
          //送信中ダイアログを閉じる
          this.sendMailOverlay = false;
          //結果ダイアログ
          this.sendMailGiftResultTitle = "メール送信完了";
          this.sendMailGiftResultText = "リアクションメールの送信が完了しました！";
          this.sendMailGiftResultDialog = true;
        } else if (code === 2) {
          //送信先が無効ユーザーの場合（送信失敗）
          //送信中ダイアログを閉じる
          this.sendMailOverlay = false;
          //結果ダイアログ
          this.sendMailGiftResultTitle = "メール送信失敗";
          this.sendMailGiftResultText = "リアクションメールの宛先が\n無効なユーザーのため\n送信できませんでした。";
          this.sendMailGiftResultDialog = true;
        } else {
          //バックエンドシステムエラーによるメール送信失敗
          //送信中ダイアログを閉じる
          this.sendMailOverlay = false;
          //結果ダイアログ
          this.sendMailGiftResultTitle = "メール送信失敗";
          this.sendMailGiftResultText = "リアクションメールの送信に失敗しました。\n再度送信するか、管理者にお問い合わせ下さい。";
          this.sendMailGiftResultDialog = true;
        }
      } catch (err) {
        //フロントエンドシステムエラーによるメール送信失敗
        //送信中ダイアログを閉じる
        this.sendMailOverlay = false;
        //結果ダイアログ
        this.sendMailGiftResultTitle = "メール送信失敗";
        this.sendMailGiftResultText = "リアクションメールの送信に失敗しました。\n再度送信するか、管理者にお問い合わせ下さい。";
        this.sendMailGiftResultDialog = true;
      }
    },
    //返事用ギフト送信先セット
    async giftReceiverSet (sendMailData) {
      this.sendGiftOverlay = true;
      //履歴のfrom社員番号からユーザー情報取得
      let giftReceiveUserNumber = sendMailData.from;
      try {
        let giftReceiveUser = await Methods.memberget("user", giftReceiveUserNumber);
        console.log("giftReceiveUser:" + JSON.stringify(giftReceiveUser));
        console.log("giftReceiveUser.length:" + giftReceiveUser.data.length);
        if (giftReceiveUser.data.error) {
          //バックエンドでシステムエラー発生
          this.sendGiftOverlay = false;
          this.sendMailGiftResultTitle = "システムエラー";
          this.sendMailGiftResultText = "システムエラーが発生しました。\n再度ギフトボタンを押下するか、\n管理者にお問い合わせ下さい。";
          this.sendMailGiftResultDialog = true;
        } else {
          //バックエンド処理成功パターン
          if (giftReceiveUser.data.length > 0) {
            this.sendGiftOverlay = false;
            //Top.vueへ遷移
            this.$router.push({ name: "top", params: { earnCoinMemberProps: giftReceiveUser.data } });
          } else {
            this.sendGiftOverlay = false;
            this.sendMailGiftResultTitle = "ギフト宛先エラー";
            this.sendMailGiftResultText = "無効なユーザーのためギフトを贈れません。\nご不明点は、管理者にお問い合わせ下さい。";
            this.sendMailGiftResultDialog = true;
          }
        }
      } catch (err) {
        this.sendGiftOverlay = false;
        //フロントエンドシステムエラー
        this.sendMailGiftResultTitle = "システムエラー";
        this.sendMailGiftResultText = "システムエラーが発生しました。\n再度ギフトボタンを押下するか、\n管理者にお問い合わせ下さい。";
        this.sendMailGiftResultDialog = true;
      }
    },
  },
};
</script>

<style>
.Detail_column {
  font-weight: bold;
  color: #0096e6;
}
</style>

<style lang="scss" scoped>
#mypage {
  ::v-deep main {
    header {
      > .v-toolbar__content {
        padding: 0 32px;
      }
    }
  }
}
#tokencss {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>
